import React from 'react';
import classnames from 'classnames';
import './button-titlebar.css';
import { Link } from 'react-router-dom';


interface ButtonTitlebarProps {
  title: string,
  selected?: boolean,
  to?: string,
  primary?: boolean,
}


const ButtonTitlebar: React.FC<ButtonTitlebarProps> = ({ title, selected, to, primary }) => {
  return (
    <Link
      className={classnames('button-titlebar', { 'button-titlebar--selected': selected, 'button-titlebar--primary' : primary })}
      to={ to || '/' }
    >
      { title }
    </Link>
  );
};


export default ButtonTitlebar;