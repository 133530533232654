import React from 'react';
import WithAuthentication from '../../hoc/auth/WithAuthentication';
import WithWs from '../../hoc/ws/WithWs';
import { tr, trans } from '../../../translation/translations';

import './dashboard.css';
import TemplatePageWithNavigation from '../../shared/template-page-navigation/TemplatePageWithNavigation';
import DashboardOverview from '../../shared/dashboard-overview/DashboardOverview';
import DashboardCharts from '../../shared/dashboard-charts/DashboardCharts';
import DashboardChargingEvents from '../../shared/dashboard-charging-events/DashboardChargingEvents';
import DashboardInfo from '../../shared/dashboard-info/DashboardInfo';




interface DashboardProps {
}


interface DashboardState {
}


export class Dashboard extends React.Component<DashboardProps, DashboardState> {


  state = {
    infoBlockType: 'group',
    infoBlockId: ''
  }


  handleChildClick = (options: { infoBlockType: string, infoBlockId: string }) => {
    const { infoBlockType, infoBlockId } = options;
    this.setState({ infoBlockType, infoBlockId });
  };


  render() {

    const { infoBlockType, infoBlockId } = this.state;

    return (
      <TemplatePageWithNavigation title={tr(trans.viewDashboard.pageTitle)}>
        <div className="dashboard">


          <div className="dashboard-section">
            <DashboardOverview
              onItemClick={(options: {infoBlockType: string, infoBlockId: string}) => this.handleChildClick({ infoBlockType: options.infoBlockType, infoBlockId: options.infoBlockId })} 
            />
            <DashboardCharts />
            <DashboardChargingEvents />
          </div>


          <div className="dashboard-section">
            <DashboardInfo infoScreen={infoBlockType} infoId={infoBlockId} />
          </div>


        </div>
      </TemplatePageWithNavigation>
    );
  }


};


export default WithAuthentication(WithWs(Dashboard));