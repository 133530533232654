
export const IconBoxEdit = (props: { size?: number, color?: string }) => (
  <span className="app-icon app-icon--tables app-icon-edit">
    <svg width={props.size ? props.size : '24px'} height={props.size ? props.size : '24px'} viewBox="0 0 416 416" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g fill={props.color ? props.color : '#fff'}>
                <path d="M40.84,300.776 C36.744,300.776 32.656,299.216 29.528,296.088 C23.28,289.84 23.28,279.712 29.528,273.464 L279.56,23.432 C285.8,17.184 295.936,17.184 302.184,23.432 C308.432,29.68 308.432,39.808 302.184,46.056 L52.152,296.088 C49.032,299.208 44.936,300.776 40.84,300.776 Z" id="Path"></path>
                <path d="M16.136,416 C15.024,416 13.896,415.88 12.768,415.64 C4.128,413.784 -1.376,405.28 0.48,396.64 L25.2,281.416 C27.056,272.776 35.6,267.288 44.2,269.128 C52.84,270.984 58.344,279.488 56.488,288.128 L31.768,403.352 C30.16,410.864 23.52,416 16.136,416 Z" id="Path"></path>
                <path d="M131.344,391.28 C127.248,391.28 123.16,389.72 120.032,386.592 C113.784,380.344 113.784,370.216 120.032,363.968 L370.064,113.944 C376.304,107.696 386.44,107.696 392.688,113.944 C398.936,120.192 398.936,130.32 392.688,136.568 L142.664,386.592 C139.536,389.72 135.44,391.28 131.344,391.28 Z" id="Path"></path>
                <path d="M16.104,416 C8.72,416 2.088,410.864 0.472,403.352 C-1.376,394.712 4.12,386.208 12.76,384.352 L127.984,359.632 C136.632,357.8 145.136,363.288 146.984,371.92 C148.832,380.56 143.336,389.064 134.696,390.92 L19.472,415.64 C18.344,415.888 17.216,416 16.104,416 Z" id="Path"></path>
                <path d="M336.12,186.504 C332.024,186.504 327.928,184.944 324.808,181.816 L234.304,91.312 C228.056,85.064 228.056,74.936 234.304,68.688 C240.544,62.44 250.688,62.44 256.928,68.688 L347.432,159.192 C353.68,165.44 353.68,175.568 347.432,181.816 C344.312,184.944 340.216,186.504 336.12,186.504 Z" id="Path"></path>
                <path d="M381.376,141.256 C377.28,141.256 373.184,139.696 370.056,136.568 C363.808,130.32 363.808,120.192 370.056,113.936 C378.96,105.032 383.864,92.976 383.864,80 C383.864,67.024 378.96,54.968 370.056,46.064 C361.144,37.152 349.088,32.248 336.112,32.248 C323.136,32.248 311.08,37.152 302.176,46.064 C295.936,52.312 285.808,52.32 279.544,46.064 C273.296,39.816 273.296,29.688 279.544,23.432 C294.488,8.48 314.576,0.248 336.112,0.248 C357.64,0.248 377.736,8.48 392.68,23.432 C407.632,38.376 415.864,58.464 415.864,80 C415.864,101.536 407.632,121.624 392.68,136.568 C389.568,139.688 385.472,141.256 381.376,141.256 Z" id="Path"></path>
            </g>
        </g>
    </svg>
  </span>
);


export const IconBoxDelete = (props: { size?: number, color?: string }) => (
  <span className="app-icon app-icon--tables app-icon-delete">
    <svg width={props.size ? props.size : '24px'} height={props.size ? props.size : '24px'} viewBox="0 0 416 512" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g fill={props.color ? props.color : '#fff'}>
                <path d="M376,64 L288,64 L288,48 C288,21.533 266.467,0 240,0 L176,0 C149.533,0 128,21.533 128,48 L128,64 L40,64 C17.944,64 0,81.944 0,104 L0,160 C0,168.836 7.164,176 16,176 L24.744,176 L38.567,466.283 C39.788,491.919 60.848,512 86.512,512 L329.488,512 C355.153,512 376.213,491.919 377.433,466.283 L391.256,176 L400,176 C408.836,176 416,168.836 416,160 L416,104 C416,81.944 398.056,64 376,64 Z M160,48 C160,39.178 167.178,32 176,32 L240,32 C248.822,32 256,39.178 256,48 L256,64 L160,64 L160,48 Z M32,104 C32,99.589 35.589,96 40,96 L376,96 C380.411,96 384,99.589 384,104 L384,144 C379.069,144 52.433,144 32,144 L32,104 Z M345.469,464.761 C345.062,473.306 338.042,480 329.488,480 L86.512,480 C77.957,480 70.937,473.306 70.531,464.761 L56.78,176 L359.22,176 L345.469,464.761 Z" id="Shape" fillRule="nonzero"></path>
                <path d="M208,448 C216.836,448 224,440.836 224,432 L224,224 C224,215.164 216.836,208 208,208 C199.164,208 192,215.164 192,224 L192,432 C192,440.836 199.163,448 208,448 Z" id="Path"></path>
                <path d="M288,448 C296.836,448 304,440.836 304,432 L304,224 C304,215.164 296.836,208 288,208 C279.164,208 272,215.164 272,224 L272,432 C272,440.836 279.163,448 288,448 Z" id="Path"></path>
                <path d="M128,448 C136.836,448 144,440.836 144,432 L144,224 C144,215.164 136.836,208 128,208 C119.164,208 112,215.164 112,224 L112,432 C112,440.836 119.163,448 128,448 Z" id="Path"></path>
            </g>
        </g>
    </svg>
  </span>
);


export const IconTablesCircleMore = (props: { size?: number, color?: string }) => (
  <span className="app-icon app-icon--tables app-icon-circle-more">
    <svg width={props.size ? props.size : '24px'} height={props.size ? props.size : '24px'} viewBox="0 0 28 28" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fill={props.color ? props.color : '#2A2C44'}>
          <path d="M14,0 C6.28,0 0,6.28 0,14 C0,21.72 6.28,28 14,28 C21.72,28 28,21.72 28,14 C28,6.28 21.72,0 14,0 Z M14,26 C7.383,26 2,20.617 2,14 C2,7.383 7.383,2 14,2 C20.617,2 26,7.383 26,14 C26,20.617 20.617,26 14,26 Z" id="Shape" fillRule="nonzero"></path>
          <circle id="Oval" cx="14" cy="14" r="2"></circle>
          <circle id="Oval" cx="6.5" cy="14" r="2"></circle>
          <circle id="Oval" cx="21.5" cy="14" r="2"></circle>
        </g>
      </g>
    </svg>
  </span>
);


export const IconTablesMore = (props: { size?: number, color?: string }) => (
  <span className="app-icon app-icon--tables app-icon-more">
    <svg fill="#fff" height={props.size ? props.size : '24px'} width={props.size ? props.size : '24px'} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 210 210">
      <g id="XMLID_27_">
        <path id="XMLID_28_" d="M25,80C11.215,80,0,91.215,0,105s11.215,25,25,25c13.785,0,25-11.215,25-25S38.785,80,25,80z"/>
        <path id="XMLID_30_" d="M105,80c-13.785,0-25,11.215-25,25s11.215,25,25,25c13.785,0,25-11.215,25-25S118.785,80,105,80z"/>
        <path id="XMLID_71_" d="M185,80c-13.785,0-25,11.215-25,25s11.215,25,25,25c13.785,0,25-11.215,25-25S198.785,80,185,80z"/>
      </g>
    </svg>
  </span>
);
