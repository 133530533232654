import { tr, trans } from "../../../translation/translations"
import { IconCarretLeft, IconCarretRight } from "../icons/IconsNavigation"



interface TableFooterProps {
  currentPage: number,
  perPage: number,
  itemsCount: number,
  prevPage: (options: number) => void,
  nextPage: (options: number) => void,
  updatePagination: (event: React.ChangeEvent<HTMLSelectElement>) => any,
}


const TableFooter: React.FC<TableFooterProps> = ({ currentPage, perPage, itemsCount, prevPage, nextPage, updatePagination }) => {
  return (

      <div className="pagination">

          <div className="pagination__nav">
            {currentPage > 0 ? (
              <span className="pagination__prev pagination__prev--active" onClick={() => prevPage(currentPage - 1)}>
                <IconCarretLeft color="#fff"/>
              </span>
            ) : (
              <span className="pagination__prev">
                <IconCarretLeft color="#e5e5e5" />
              </span>
            )} 

            {itemsCount < perPage * (currentPage + 1) +1 ? (
              <span className="pagination__next">
                <IconCarretRight color="#e5e5e5" />
              </span>
            ) : (                              
              <span className="pagination__next pagination__next--active" onClick={() => nextPage(currentPage + 1)}>
                <IconCarretRight color="#fff" />
              </span>
            )} 
          </div>


          <div className="pagination__tools">
            <span className="pagination__perpage-text">{ tr(trans.pagination.itemsPerPage) }</span>
            <span className="pagination__perpage-select">
              <select className="pagination__select" value={perPage} onChange={updatePagination}>
                <option>10</option>
                <option>25</option>
                <option>50</option>
                <option>100</option>
              </select>
            </span>
            <span className="pagination__item-num">
              {itemsCount === 0 ? 0 : currentPage * perPage + 1} - {itemsCount < perPage * (currentPage + 1) ? itemsCount : perPage * (currentPage + 1)} {'of'} {itemsCount}
            </span>
          </div>

      </div>

  )
}


export default TableFooter