import { useState, useEffect } from 'react';
import classnames from 'classnames';


function AppIsOnline() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [showNotice, setShowNotice] = useState(false);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      console.log('Regained internet connection');
    };

    const handleOffline = () => {
      setIsOnline(false);
      setShowNotice(true);
      console.log('Lost internet connection');
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);



  const handleHideNotice = () => {
    setShowNotice(false);
  };

  return (
    <div className={classnames('app-offline-overlay', { 'app-offline-overlay--visible': !isOnline && showNotice })} >
      <div className="app-offline__content">
        <h1 className="app-offline__title">Application if offline</h1>
        <p className="app-offline__text">Please connect to the internet or press the button below to hide this notice.</p>
        <button className="button button--primary button--large" onClick={handleHideNotice}>Hide this notice</button>
      </div>
    </div>
  );
}


export default AppIsOnline;