import { AUTH, PRELOADER } from '../types/reducer.types';
import { errorNotification, successNotification } from '../functions/notifications';



const fetchAuth = (options: { endpoint: string, options: any, SUCCESS_TYPE: string }) => (dispatch:any) => {

  dispatch({ type: AUTH.FETCH });
  let authorizationHeader: any = [];
  dispatch({ type: PRELOADER.SHOW_PRELOADER });
  return fetch(`/api/auth/${options.endpoint}`, options.options)
          .then(response => {
              authorizationHeader = response.headers.get('Authorization');
              return response.json();
            }
          )
          .then(json => {
            dispatch({ type: PRELOADER.HIDE_PRELOADER });
            if (json.errors !== undefined) {
              dispatch({ type: AUTH.FETCH_ERROR, ...json, authorizationHeader });
              json && json.errors && json.errors.map((item: {msg: string}) => {
                if(item.msg) {
                  if(item.msg !== 'You are not autnethicated. Please login.') {
                    errorNotification(item.msg);
                  }
                }
                return null;
              });
            } else {
              dispatch({ type: options.SUCCESS_TYPE, ...json, authorizationHeader });
              json && json.success && json.success.map((item: {msg: string}) => {
                if(item.msg) {
                  successNotification(item.msg);
                }
                return null
              });
            }
          })
          .catch(error => 
            dispatch({ type: AUTH.FETCH_ERROR, errors: [{ msg: error }], authorizationHeader })
          );
  }


  export const register = (options: { register_email: string, register_password: string, register_password_confirmation: string }) => fetchAuth({
    endpoint: `register`,
    options: {
      method: 'POST',
      body: JSON.stringify({ register_email: options.register_email, register_password: options.register_password, register_password_confirmation: options.register_password_confirmation }),
      headers: { 
        'Content-Type': 'application/json'
      }
    },
    SUCCESS_TYPE: AUTH.FETCH_REGISTER_SUCCESS
  });


  export const registerPinResend = (options: { register_email: string }) => fetchAuth({
    endpoint: `register-pin-resend`,
    options: {
      method: 'POST',
      body: JSON.stringify({ register_email: options.register_email }),
      headers: { 
        'Content-Type': 'application/json'
      }
    },
    SUCCESS_TYPE: AUTH.FETCH_REGISTER_PIN_RESEND_SUCCESS
  });


  export const confirmRegistrationPin = (register_pin: string ) => fetchAuth({
    endpoint: `register-pin`,
    options: {
      method: 'POST',
      body: JSON.stringify({ register_pin }),
      headers: { 
        'Content-Type': 'application/json'
      }
    },
    SUCCESS_TYPE: AUTH.FETCH_REGISTER_PIN_SUCCESS
  });


  export const login = (options: { login_email: string, login_password: string, keepMeSigned: boolean }) => fetchAuth({
    endpoint: `login`,
    options: {
      method: 'POST',
      body: JSON.stringify({ login_email: options.login_email, login_password: options.login_password, keepMeSigned: options.keepMeSigned }),
      headers: { 
        'Content-Type': 'application/json'
      }
    },
    SUCCESS_TYPE: AUTH.FETCH_LOGIN_SUCCESS
  });


  export const checkAuth = () => fetchAuth({
    endpoint: ``,
    options: { 
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      },
      credentials: 'include' 
    },
    SUCCESS_TYPE: AUTH.FETCH_AUTHENTICATED_SUCCESS
  });


  export const logout = () => fetchAuth({
    endpoint: `logout`,
    options: { 
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      },
      credentials: 'include' 
    },
    SUCCESS_TYPE: AUTH.FETCH_LOGOUT_SUCCESS
  });


  export const logoutEverywhere = () => fetchAuth({
    endpoint: `logoutall`,
    options: { 
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      },
      credentials: 'include' 
    },
    SUCCESS_TYPE: AUTH.FETCH_LOGOUT_EVERYWHERE_SUCCESS
  });


  export const forgotPassword = (forgot_email: string) => fetchAuth({
    endpoint: `password/forgot`,
    options: {
      method: 'POST',
      body: JSON.stringify({ forgot_email }),
      headers: { 
        'Content-Type': 'application/json'
      }
    },
    SUCCESS_TYPE: AUTH.FETCH_PASSWORD_FORGOT_SUCCESS
  });


  export const checkTokenValid = (reset_token: string) => fetchAuth({
    endpoint: `password/check`,
    options: {
      method: 'POST',
      body: JSON.stringify({ reset_token }),
      headers: { 
        'Content-Type': 'application/json'
      }
    },
    SUCCESS_TYPE: AUTH.FETCH_PASSWORD_CHECK_SUCCESS
  });


  export const resetPassword = (options: { reset_password: string, confirm_reset_password: string, reset_token: string }) => fetchAuth({
    endpoint: `password/reset`,
    options: {
      method: 'POST',
      body: JSON.stringify({ reset_password: options.reset_password, confirm_reset_password: options.confirm_reset_password, reset_token: options.reset_token }),
      headers: { 
        'Content-Type': 'application/json'
      }
    },
    SUCCESS_TYPE: AUTH.FETCH_PASSWORD_RESET_SUCCESS
  });


  export const changePassword = (options: { password_confirmation: string, password_old: string, password_new: string }) => fetchAuth({
    endpoint: `password/change`,
    options: {
      method: 'PUT',
      body: JSON.stringify({ password_old: options.password_old, password_new: options.password_new, password_confirmation: options.password_confirmation }),
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      },
    },
    SUCCESS_TYPE: AUTH.FETCH_PASSWORD_CHANGE_SUCCESS
  });


  export const fetchLoginLogs = (options: {limit: number, skip: number}) => fetchAuth({
    endpoint: `login-logs?limit=${options.limit}&skip=${options.skip}`,
    options: { 
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      },
      credentials: 'include' 
    },
    SUCCESS_TYPE: AUTH.FETCH_LOGIN_LOGS_SUCCESS
  });


  export const updateAccountSettings = (options: { account_energy_cost: string, account_currency: string }) => fetchAuth({
    endpoint: `account/settings`,
    options: {
      method: 'PUT',
      body: JSON.stringify({ account_energy_cost: options.account_energy_cost, account_currency: options.account_currency }),
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      },
    },
    SUCCESS_TYPE: AUTH.FETCH_ACCOUNT_SETTINGS_UPDATE_SUCCESS
  });


  export const getAccountSettings = () => fetchAuth({
    endpoint: `account/settings`,
    options: { 
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      },
      credentials: 'include' 
    },
    SUCCESS_TYPE: AUTH.FETCH_ACCOUNT_SETTINGS_SUCCESS
  });