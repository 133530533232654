import React from 'react';
import './strong-password-meter.css';


interface StrongPasswordMeterProps {
  password: string,
}


export class StrongPasswordMeter extends React.Component<StrongPasswordMeterProps> {

  createPasswordLabel = (result: {score: number}) => {
    switch (result.score) {
      case 0:
        return 'weak';
      case 1:
        return 'weak';
      case 2:
        return 'weak';
      case 3:
        return 'weak';
      case 4:
        return 'weak';
      case 5:
        return 'strong';
      default:
        return 'weak';
    }
  }


  getPasswordStrengthScore = (password: string) => {
    const pattern_valid = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,}$/;
    const pattern_specialchar = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
    const pattern_lowercase = /[a-z]/;
    const pattern_upercase = /[A-Z]/;
    const pattern_number = /\d+/;
    let score = 0;

    const lowercase = pattern_lowercase.test(password);
    const specialchar = pattern_specialchar.test(password);
    const uppercase = pattern_upercase.test(password);
    const number = pattern_number.test(password);
    const length = password.length > 8;
    const ok = pattern_valid.test(password);

    if(length) { score = score + 1; }
    if(lowercase) { score= score + 1; }
    if(uppercase) { score= score + 1; }
    if(specialchar) { score = score + 1; }
    if(number) { score = score + 1; }
    if(ok) { score = 5; }
    return { score };
  }


  render() {
    const { password } = this.props;
    const testedResult = this.getPasswordStrengthScore(this.props.password);
    return (
      <div className="strong-password-meter">
        <progress
          id="strong-password-meter"
          className={`strong-password-meter-progress strength-${ this.createPasswordLabel(testedResult) }`}
          value={testedResult.score}
          max="5"
        />
        <label
          className="strong-password-meter-label"
          htmlFor="strong-password-meter"
        >
          { password && (
            <>
              Password level: <strong>{ this.createPasswordLabel(testedResult) }</strong>
            </>
          )}
        </label>
      </div>
    );
  }
}


export default StrongPasswordMeter;