import { PERSON } from '../types/reducer.types';
import fetchStates from '../types/fetchState.types';
import InterfaceReducerPerson from '../intefaces/InterfaceReducerPerson';


const DEFAULT_PERSON_STATE: InterfaceReducerPerson = { 
  status: '',
  fields: [],
  msg: [],
  users_count: 0,
  users: [],
  user: []
}


const person = (state = DEFAULT_PERSON_STATE, action: any) => {
  if(action.authorizationHeader) {
    localStorage.setItem('accessToken', action.authorizationHeader);
  }
  switch(action.type) {
    case PERSON.FETCH:
      return { 
        ...state, 
        status: fetchStates.fetching,
        msg: [],
        fields: [],
      };
    case PERSON.FETCH_ERROR:
      return { 
        ...state, 
        status: fetchStates.error,
        msg: action.errors,
        fields: action.errors && action.errors.map((error: {path: string}) => error.path !== undefined && error.path.toString()),
      };
    case PERSON.FETCH_PERSON_COUNT_SUCCESS:
      return {
        ...state,
        userd_count: action.users_count,
        status: fetchStates.success,
      };
    case PERSON.FETCH_PERSON_SUCCESS:
      return {
        ...state,
        users: action.users,
        users_count: action.users_count,
        status: fetchStates.success,
      };
    case PERSON.FETCH_PERSON_BY_ID_SUCCESS:
      return {
        ...state,
        user: action.user,
        status: fetchStates.success,
      };
    case PERSON.FETCH_INSERT_PERSON_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
        fields: action.errors && action.errors.map((error: {path: string}) => error.path !== undefined && error.path.toString()),
      };
    case PERSON.FETCH_UPDATE_PERSON_BY_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
        fields: action.errors && action.errors.map((error: {path: string}) => error.path !== undefined && error.path.toString()),
      };
    case PERSON.FETCH_DELETE_PERSON_BY_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success
      };
    default:
      return state;
  }
};


export default person;