import React from 'react';
import { connect } from 'react-redux';
import fetchStates from '../../../types/fetchState.types';
import { fetchUserById, updateUserById } from '../../../actions/person.action';
import { fetchAllAccountGroups } from '../../../actions/group.action';

import WithAuthentication from '../../hoc/auth/WithAuthentication';
import WithRouter from '../../hoc/router/WithRouter';
import WithWs from '../../hoc/ws/WithWs';

import TemplatePageWithNavigation from '../../shared/template-page-navigation/TemplatePageWithNavigation';
import './users-edit.css';
import { tr, trans } from '../../../translation/translations';
import classnames from 'classnames';
import { IconVisibility, IconVisibilityOff, IconWarning } from '../../shared/icons/IconsForm';
import Box from '../../shared/box/Box';
import InterfaceReducerPerson from '../../../intefaces/InterfaceReducerPerson';
import InterfaceReducerGroup from '../../../intefaces/InterfaceReducerGroup';
import WithPreloader from '../../hoc/preloader/WithPreloader';
import { USER_AUTHORIZED } from '../../../constants';


interface UserEditProps {
  router: { location: string, navigate: (to: any) => any, params: { user_id: string } },
  person: InterfaceReducerPerson,
  group: InterfaceReducerGroup,
  fetchUserById: (user_id: string) => Promise<void>,
  updateUserById: (user: any) => Promise<void>,
  fetchAllAccountGroups: () => Promise<any>
}


interface UserEditState {
  user: {
    user_id: string,
    user_email: string,
    user_title: string,
    user_auth_groups: Array<string>,
    user_password: string,
    user_authorized: Object,
  },
  groups_account: Array<any>,
  formSubmitted: boolean,
  formError: boolean,
  passwordShow: boolean,
}


export class UserEdit extends React.Component<UserEditProps, UserEditState> {


  state = {
    user: {
      user_id: '',
      user_email: '',
      user_title: '',
      user_auth_groups: [],
      user_password: '',
      user_authorized: USER_AUTHORIZED
    },
    groups_account: [],
    formSubmitted: false,
    formError: false,
    passwordShow: false
  }


  componentDidMount(): void {
    this.getUserById();
  }


  getAllAccountGroups = async () => {
    await this.props.fetchAllAccountGroups();
    if(this.props.group.status === fetchStates.success) {
      const { groups_account } = this.props.group;
      this.setState({ groups_account });
    }
  }


  getUserById = async () => {
    await this.props.fetchUserById(this.props.router.params.user_id);
    if(this.props.person.status === fetchStates.success) {
      let { user } = this.props.person;
      this.setState({ user: { ...user, user_password: '' } });
      this.getAllAccountGroups();
    }
  }


  handleTogglePersonAuthGroup = (group_id: string) => {
    let { user_auth_groups }:any = this.state.user;
      if (user_auth_groups.includes(group_id)) {
        this.setState({ user: { ...this.state.user, user_auth_groups: user_auth_groups.filter((id:any) => id !== group_id) } });
      } else {
        this.setState({ user: { ...this.state.user, user_auth_groups: [ ...user_auth_groups, group_id ]} });
      }
  }


  handleOnDeviceUpdateSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState({ formSubmitted: true });
    await this.props.updateUserById(this.state.user);
    this.setState({ formSubmitted: false });
    if(this.props.person.status === fetchStates.error) {
      this.setState({ formError: true });
      const self = this;
      setTimeout(function() { 
        if(self.state.formError === true) {
          self.setState({ formError: false });
        }
      }, 250);
    }
    if(this.props.person.status === fetchStates.success) {
      this.props.router.navigate('/users');
    }
    this.setState({ formSubmitted: false });
  }


  render() {

    const { groups_account, user, formSubmitted, formError, passwordShow } = this.state;
    const { fields, status } = this.props.person;

    return (
      <TemplatePageWithNavigation title={ tr(trans.viewUsersEdit.pageTitle) } buttonBack>
        <Box>
          <form className={classnames('form', { 'form-error': formError })} onSubmit={this.handleOnDeviceUpdateSubmit}>
            <div className="form-section">


              <div className="form-group">
                <label 
                  htmlFor="user_email"
                  className={classnames('label', { 'label--error': fields && fields.includes('user.user_email') })}
                >
                    { fields && fields.includes('user.user_email') && <IconWarning size={16} color="#fff" /> } { tr(trans.viewUsersEdit.form.labelUserEmail) }
                </label>
                <div className="input-group">
                  <input 
                      type="text"
                      name="user_email"
                      id="user_email" 
                      value={ user.user_email }
                      className="input input--form"
                      disabled={true}
                    />
                </div>
              </div>

              <div className="form-group">
                <label 
                  htmlFor="user_title"
                  className={classnames('label', { 'label--error': fields && fields.includes('user.user_title') })}
                >
                    { fields && fields.includes('user.user_title') && <IconWarning size={16} color="#fff" /> } { tr(trans.viewUsersEdit.form.labelUserTtile) }
                </label>
                <div className="input-group">
                  <input 
                      type="text"
                      name="user_title"
                      id="user_title" 
                      value={ user.user_title }
                      onChange={e => this.setState({ user: { ...user, user_title: e.target.value } })}
                      className={classnames('input input--form', { 'input--error': fields && fields.includes('user.user_title') })}
                    />
                </div>
              </div>



              <div className="form-group">
                <label 
                  htmlFor="user_password"
                  className={classnames('label', { 'label--error': fields && fields.includes('user.user_password') })}
                >
                  { fields && fields.includes('user.user_password') && <IconWarning size={16} color="#fff" /> }{ tr(trans.viewLogin.form.labelPassword) }
                </label>
                <div className="input-group">
                  <span className="password-reveal" onClick={ e => this.setState({ passwordShow: !passwordShow }) }>
                    { passwordShow === false ? (
                      <IconVisibility size={18} color="#000" />
                    ) : (
                      <IconVisibilityOff size={18} color="#000" />
                    )}
                  </span>
                  <input
                    id="user_password"
                    type={ passwordShow === false ? 'password' : 'text' } 
                    autoComplete="user_password"
                    value={ user.user_password }
                    onChange={e => this.setState({ user: { ...user, user_password: e.target.value } })}
                    className={classnames('input input--form', { 'input--error': fields && fields.includes('user.user_password') })}
                  />
                </div>
              </div>



              <div className="form-group">
                <label 
                  className={classnames('label', { 'label--error': fields && fields.includes('user.user_authorization') })}
                >
                    { fields && fields.includes('user.user_authorization') && <IconWarning size={16} color="#fff" /> } { tr(trans.viewUsersAdd.form.labelUserAuthorization) }
                </label>

                <div className="input-group">
                  <div>
                    <label htmlFor="manage_devices">
                      <input 
                        type="checkbox"
                        id="manage_devices"
                        name="manage_devices"
                        checked={ Boolean(user.user_authorized.manage_devices) }
                        onChange={ () => this.setState({ user: {...this.state.user, user_authorized: {...this.state.user.user_authorized, manage_devices: !this.state.user.user_authorized.manage_devices }}}) }
                      /> 
                      { tr(trans.viewUsersAdd.form.labelManageDevices) }
                    </label>
                  </div>
                </div>

                <div className="input-group">
                  <div>
                    <label htmlFor="manage_groups">
                      <input 
                        type="checkbox"
                        id="manage_groups"
                        name="manage_groups"
                        checked={ Boolean(user.user_authorized.manage_groups) }
                        onChange={ () => this.setState({ user: {...this.state.user, user_authorized: {...this.state.user.user_authorized, manage_groups: !this.state.user.user_authorized.manage_groups }}}) }
                      /> 
                      { tr(trans.viewUsersAdd.form.labelManageGroups) }
                    </label>
                  </div>
                </div>

                <div className="input-group">
                  <div>
                    <label htmlFor="manage_users">
                      <input 
                        type="checkbox"
                        id="manage_users"
                        name="manage_users"
                        checked={ Boolean(user.user_authorized.manage_users) }
                        onChange={ () => this.setState({ user: {...this.state.user, user_authorized: {...this.state.user.user_authorized, manage_users: !this.state.user.user_authorized.manage_users }}}) }
                      /> 
                      { tr(trans.viewUsersAdd.form.labelManageUsers) }
                    </label>
                  </div>
                </div>

                <div className="input-group">
                  <div>
                    <label htmlFor="manage_events">
                      <input 
                        type="checkbox"
                        id="manage_events"
                        name="manage_events"
                        checked={ Boolean(user.user_authorized.manage_events) }
                        onChange={ () => this.setState({ user: {...this.state.user, user_authorized: {...this.state.user.user_authorized, manage_events: !this.state.user.user_authorized.manage_events }}}) }
                      /> 
                      { tr(trans.viewUsersAdd.form.labelManageEvents) }
                    </label>
                  </div>
                </div>

                <div className="input-group">
                  <div>
                    <label htmlFor="manage_energy_prices">
                      <input 
                        type="checkbox"
                        id="manage_energy_prices"
                        name="manage_energy_prices"
                        checked={ Boolean(user.user_authorized.manage_energy_prices) }
                        onChange={ () => this.setState({ user: {...this.state.user, user_authorized: {...this.state.user.user_authorized, manage_energy_prices: !this.state.user.user_authorized.manage_energy_prices }}}) }
                      /> 
                      { tr(trans.viewUsersAdd.form.labelManagePrices) }
                    </label>
                  </div>
                </div>

              </div>



              <div className="form-group">
                <label 
                  htmlFor="group_id"
                  className={classnames('label', { 'label--error': fields && fields.includes('user.user_auth_groups') })}
                >
                    { fields && fields.includes('user.user_auth_groups') && <IconWarning size={16} color="#fff" /> } { tr(trans.viewUsersEdit.form.labelUserGroups) }
                </label>
                <div className="input-group">
                  {
                    groups_account && groups_account.map((item: { group_id: string, group_title: string }) => {
                      const { user_auth_groups }:any = this.state.user;
                      return (
                        <div key={item.group_id}>
                          <label htmlFor={ item.group_title }>
                            <input 
                              type="checkbox" 
                              id={ item.group_title } 
                              name={ item.group_title } 
                              value={ item.group_id } 
                              checked={ user_auth_groups.includes(item.group_id) }
                              onChange={ () => this.handleTogglePersonAuthGroup(item.group_id) }
                            /> 
                              { item.group_title }
                          </label>
                        </div>
                      )
                    })
                  }
                </div>
              </div>


              <div className="form-group">
                <input 
                  type="submit" 
                  value={ tr(trans.viewUsersEdit.form.buttonUpdateUser) } 
                  className="button button--primary button--small"
                  disabled={ formSubmitted && status === 'fetching' ? true : false }
                />
              </div>

            </div>
          </form>
        </Box>
      </TemplatePageWithNavigation>
    );
  }
};


export default WithAuthentication(WithWs(WithPreloader(WithRouter(connect(
  ({ person, group }) => ({ person, group }),
  { fetchUserById, updateUserById, fetchAllAccountGroups }
)(UserEdit)))));