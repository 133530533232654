import React from 'react';
import { connect } from 'react-redux';
import fetchStates from '../../../types/fetchState.types';
import { fetchEvents } from '../../../actions/event.action';

import WithPreloader from '../../hoc/preloader/WithPreloader';
import WithAuthentication from '../../hoc/auth/WithAuthentication';
import WithRouter from '../../hoc/router/WithRouter';
import WithWs from '../../hoc/ws/WithWs'

import { tr, trans } from '../../../translation/translations';
import './events.css';
import TemplatePageWithNavigation from '../../shared/template-page-navigation/TemplatePageWithNavigation';
import InterfaceReducerEvent from '../../../intefaces/interfaceReducerEvent';
import BoxTitlebar from '../../shared/box-titlebar/BoxTitlebar';
import Box from '../../shared/box/Box';
import TableFooter from '../../shared/table-footer/TableFooter';
import moment from 'moment';
import { IconNavEvents } from '../../shared/icons/IconsNavigation';



interface EventsProps {
  router: { location: string, navigate: (to: any) => any },
  event: InterfaceReducerEvent,
  fetchEvents: (options: { limit: number, skip: number }) => Promise<void>,
}


interface EventsState {
  events: Array<any>,
  events_count: number,
  currentPage: number,
  perPage: number,
}


export class Events extends React.Component<EventsProps, EventsState> {

  state = {
    events: [],
    events_count: 0,
    perPage: 10,
    currentPage: 0,
  }


  componentDidMount(): void {
    const { perPage, currentPage } = this.state;
    this.reloadPage({ perPage, currentPage });
  }


  updatePagination = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    this.setState({ perPage: parseInt(e.target.value), currentPage: 0  });
    this.reloadPage({ perPage: parseInt(e.target.value), currentPage: 0  });
    this.props.router.navigate(`/events?page=0&perPage=${e.target.value}`);
  }


  prevPage = (prevPage: number) => {
    this.setState({ currentPage: prevPage });
    this.reloadPage({ currentPage: prevPage, perPage: this.state.perPage });
    this.props.router.navigate(`/events?page=${prevPage}&perPage=${this.state.perPage}`);
  }


  nextPage = (nextPage: number) => {
    this.setState({ currentPage: nextPage });
    this.reloadPage({ currentPage: nextPage, perPage: this.state.perPage });
    this.props.router.navigate(`/events?page=${nextPage}&perPage=${this.state.perPage}`);
  }


  reloadPage = async (options: { perPage: number, currentPage: number }) => {
    await this.props.fetchEvents({ limit: options.perPage, skip: options.perPage * options.currentPage });
    if(this.props.event.status === fetchStates.success) {
      const { events, events_count } = this.props.event;
      this.setState({ events, events_count });
    }
  }
  
  render() {

    const { events, events_count, currentPage, perPage } = this.state;

    return (
      <TemplatePageWithNavigation title={ tr(trans.viewEvents.pageTitle) }>
        <Box>
          <BoxTitlebar title={ tr(trans.viewEvents.boxTitleManageEvents) } />
          <div className="table">
            <div className="row header">
              <div className="cell">{ tr(trans.viewEvents.tableHeader.type) }</div>
              <div className="cell">{ tr(trans.viewEvents.tableHeader.date) }</div>
            </div>
              { events && events.map((event: any) => {
                return (
                  <div className="row" key={ event.event_id }>
                    <div className="cell" data-title={ tr(trans.viewEvents.tableHeader.type) }>
                      <span className="table-identity-icon">
                        <IconNavEvents color="#666" size={16} />
                      </span>
                      { event.event_type }
                    </div>
                    <div className="cell" data-title={ tr(trans.viewEvents.tableHeader.date) }>
                      { moment(event.event_tst).format('YYYY/MM/DD - HH:mm:ss') }
                    </div>
                  </div>
                )
              }
            )}
          </div>
          <TableFooter 
            currentPage={ currentPage }
            perPage={ perPage }
            itemsCount={ events_count }
            prevPage={() => this.prevPage(currentPage - 1)}
            nextPage={() => this.nextPage(currentPage + 1)}
            updatePagination={this.updatePagination}
          />
        </Box>
      </TemplatePageWithNavigation>
    );
  }
};


export default WithAuthentication(WithWs(WithPreloader(WithRouter(connect(
  ({ event }) => ({ event }),
  { fetchEvents }
)(Events)))));