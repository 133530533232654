import React from 'react';
import Box from '../../shared/box/Box';
import BoxTitlebar from '../box-titlebar/BoxTitlebar';
import DashboardChart from '../charts/DashboardChart';
import ButtonTitlebar from '../button-titlebar/ButtonTitlebar';
import { tr, trans } from '../../../translation/translations';
import { connect } from 'react-redux';
import fetchStates from '../../../types/fetchState.types';
import getLocalStorageItem from '../../../functions/getLocalStorageItem';
import InterfaceReducerData from '../../../intefaces/interfaceReducerData';
import { fetchChartCost, fetchChartEnergy, fetchChartTime } from '../../../actions/data.action';
import secondsToH from '../../../functions/secondsToH';


interface DashboardChartsProps {
  data: InterfaceReducerData,
  fetchChartEnergy: (filter_time_range: string) => Promise<void>,
  fetchChartCost: (filter_time_range: string) => Promise<void>,
  fetchChartTime: (filter_time_range: string) => Promise<void>,
}


interface DashboardChartsState {
  chart_energy: { categories: Array<string>, series: Array<object> },
  chart_cost: { categories: Array<string>, series: Array<object> },
  chart_time: { categories: Array<string>, series: Array<object> },
  filter_time_range: string,
}


export class DashboardCharts extends React.Component<DashboardChartsProps, DashboardChartsState> {


  state = {
    chart_energy: {
      categories: [''],
      series: [{ data: [0] }]
    },
    chart_cost: {
      categories: [''],
      series: [{ data: [0] }]
    },
    chart_time: {
      categories: [''],
      series: [{ data: [0] }]
    },
    filter_time_range: 'week'
  }


  componentDidMount(): void {
    const { filter_time_range } = this.state;
    this.getChartEnergy(filter_time_range);
    this.getChartCost(filter_time_range);
    this.getChartTime(filter_time_range);
  }


  getChartEnergy = async (filter_time_range: string) => {
    await this.props.fetchChartEnergy(filter_time_range);
    if(this.props.data.status === fetchStates.success) {
      const { chart_energy } = this.props.data;
      this.setState({ chart_energy });
    }
  }


  getChartCost = async (filter_time_range: string) => {
    await this.props.fetchChartCost(filter_time_range);
    if(this.props.data.status === fetchStates.success) {
      const { chart_cost } = this.props.data;
      this.setState({ chart_cost });
    }
  }


  getChartTime = async (filter_time_range: string) => {
    await this.props.fetchChartTime(filter_time_range);
    if(this.props.data.status === fetchStates.success) {
      const { chart_time } = this.props.data;
      this.setState({ chart_time });
    }
  }


  countTotal = (series: any) => {
    const array = series[0].data;
    return array.reduce((sum: number, num: string) => sum + parseInt(num), 0);
  }


  handleTimeRangeFilterUpdate = (filter_time_range: string) => {
    this.setState({ filter_time_range,
      chart_energy: {
        categories: [''],
        series: [{ data: [0] }]
      },
      chart_cost: {
        categories: [''],
        series: [{ data: [0] }]
      },
      chart_time: {
        categories: [''],
        series: [{ data: [0] }]
      },
    });
    this.getChartEnergy(filter_time_range);
    this.getChartCost(filter_time_range);
    this.getChartTime(filter_time_range);
  }


  render() {

    const { chart_energy, chart_cost, chart_time, filter_time_range } = this.state;
  
    return (
      <Box>
        <BoxTitlebar title={ tr(trans.partialDashboardCharts.partialTitle) } buttons={ [
          <span key="1" onClick={() => this.handleTimeRangeFilterUpdate('today')}>
            <ButtonTitlebar title={ tr(trans.partialDashboardCharts.filter.filterToday) } selected={filter_time_range === 'today'} />
          </span>,
          <span key="2" onClick={() => this.handleTimeRangeFilterUpdate('week')}>
            <ButtonTitlebar title={ tr(trans.partialDashboardCharts.filter.filterWeek) } selected={filter_time_range === 'week'} />
          </span>,
          <span key="3" onClick={() => this.handleTimeRangeFilterUpdate('month')}>
            <ButtonTitlebar title={ tr(trans.partialDashboardCharts.filter.filterMonth) } selected={filter_time_range === 'month'} />
          </span>,
          <span key="4" onClick={() => this.handleTimeRangeFilterUpdate('year')}>
            <ButtonTitlebar title={ tr(trans.partialDashboardCharts.filter.filterYear) } selected={filter_time_range === 'year'} />
          </span>
          ] } 
        />
        <div className="box-body">
          <div className="dashboard-charts">
            <div className="dashboard-chart">
              <div className="dashboard-chart__title"><strong>{ tr(trans.partialDashboardCharts.chartTitleEnergy) }: </strong> { this.countTotal(chart_energy.series) } [kWh]</div>
              <DashboardChart categories={chart_energy.categories} series={chart_energy.series} ySuffix="kWh" />
            </div>
            <div className="dashboard-chart">
              <div className="dashboard-chart__title"><strong>{ tr(trans.partialDashboardCharts.chartTitleCost) }:</strong> { (this.countTotal(chart_cost.series) * getLocalStorageItem('account_energy_cost')).toFixed(2) } [{ getLocalStorageItem('account_currency') }]</div>
              <DashboardChart categories={chart_cost.categories} series={chart_cost.series} ySuffix={getLocalStorageItem('account_currency')} />
            </div>
            <div className="dashboard-chart">
              <div className="dashboard-chart__title"><strong>{ tr(trans.partialDashboardCharts.chartTitleTime) }:</strong> { secondsToH(this.countTotal(chart_time.series)) } [h]</div>
              <DashboardChart categories={chart_time.categories} series={chart_time.series} ySuffix="h" />
            </div>
          </div>
        </div>
      </Box>
    );

  }
};


export default connect(
  ({ data }) => ({ data }),
  { fetchChartEnergy, fetchChartCost, fetchChartTime }
)(DashboardCharts);