import { Routes, Route } from 'react-router-dom';

import NotFoundPage from './components/views/404-not-found/NotFound';
import DashboardPage from './components/views/dashboard/Dashboard';

import UserSettingsPage from './components/views/settings/Settings';
import LoginPage from './components/views/login/Login';
import RegisterPage from './components/views/register/Register';
import RegisterPinPage from './components/views/register-pin/RegisterPin';
import RegisterPinResendPage from './components/views/register-pin-resend/RegisterPinResend';
import PasswordForgotPage from './components/views/password-forgot/PasswordForgot';
import PasswordResetPage from './components/views/password-reset/PasswordReset';
import ApplicationSettingsPage from './components/views/application-settings/ApplicationSettings';
import ChangePasswordPage from './components/views/change-password/ChangePassword';
import SecurityPage from './components/views/security/Security';
import AboutPage from './components/views/about/About';
import EventsPage from './components/views/events/Events';
import GroupsPage from './components/views/groups/Groups';
import GroupsAddPage from './components/views/groups-add/GroupsAdd';
import GroupsEditPage from './components/views/groups-edit/GroupsEdit';
import UsersDeletePage from './components/views/users-delete/UsersDelete';
import UsersPage from './components/views/users/Users';
import UsersAddPage from './components/views/users-add/UsersAdd';
import UsersEditPage from './components/views/users-edit/UsersEdit';
import CostsTariffsPage from './components/views/costs-tariffs/CostsTariffs';
import DevicesWallboxPage from './components/views/devices-wallbox/DevicesWallbox';
import DeviceWallboxPage from './components/views/device-wallbox/DeviceWallbox';
import DevicesWallboxAddPage from './components/views/devices-wallbox-add/DevicesWallboxAdd';
import DevicesEditPage from './components/views/devices-wallbox-edit/DevicesWallboxEdit';
import DevicesDeletePage from './components/views/devices-wallbox-delete/DevicesWallboxDelete';
import GroupsDeletePage from './components/views/groups-delete/GroupsDelete';
import ProtectedRoute from './ProtectedRoute';



const AppRoutes = () => (

    <Routes>
      <Route path="/" element={ <DashboardPage /> } />
      <Route path="/login" element={ <LoginPage /> } />
      <Route path="/register" element={ <RegisterPage /> } />
      <Route path="/register-pin" element={ <RegisterPinPage /> } />
      <Route path="/register-pin-resend" element={ <RegisterPinResendPage /> } />
      <Route path="/password-forgot" element={ <PasswordForgotPage /> } />
      <Route path="/password-reset" element={ <PasswordResetPage /> } />
      <Route path="/events" element={ <ProtectedRoute element={ <EventsPage /> } requiredAuthorization="manage_events" /> } />
      <Route path="/devices" element={ <ProtectedRoute element={ <DevicesWallboxPage /> } requiredAuthorization="manage_devices" /> } />
      <Route path="/devices/wallbox" element={ <ProtectedRoute element={ <DevicesWallboxPage /> } requiredAuthorization="manage_devices" /> } />
      <Route path="/devices/wallbox/add" element={ <ProtectedRoute element={ <DevicesWallboxAddPage /> } requiredAuthorization="manage_devices" /> } />
      <Route path="/devices/wallbox/edit/:device_id" element={ <ProtectedRoute element={ <DevicesEditPage /> } requiredAuthorization="manage_devices" /> } />
      <Route path="/devices/wallbox/delete/:device_id" element={ <ProtectedRoute element={ <DevicesDeletePage /> } requiredAuthorization="manage_devices" /> } />
      <Route path="/devices/wallbox/:device_id" element={ <ProtectedRoute element={ <DeviceWallboxPage /> } requiredAuthorization="manage_devices" /> } />
      <Route path="/groups" element={ <ProtectedRoute element={ <GroupsPage /> } requiredAuthorization="manage_groups" /> } />
      <Route path="/groups/add" element={ <ProtectedRoute element={ <GroupsAddPage /> } requiredAuthorization="manage_groups" /> } />
      <Route path="/groups/edit/:group_id" element={ <ProtectedRoute element={ <GroupsEditPage /> } requiredAuthorization="manage_groups" /> } />
      <Route path="/groups/delete/:group_id" element={ <ProtectedRoute element={ <GroupsDeletePage /> } requiredAuthorization="manage_groups" /> } />
      <Route path="/users" element={ <ProtectedRoute element={ <UsersPage /> } requiredAuthorization="manage_users" /> } />
      <Route path="/users/add" element={ <ProtectedRoute element={ <UsersAddPage /> } requiredAuthorization="manage_users" /> } />
      <Route path="/users/edit/:user_id" element={ <ProtectedRoute element={ <UsersEditPage /> } requiredAuthorization="manage_users" /> } />
      <Route path="/users/delete/:user_id" element={ <ProtectedRoute element={ <UsersDeletePage /> } requiredAuthorization="manage_users" /> } />
      <Route path="/settings" element={ <UserSettingsPage /> } />
      <Route path="/settings/application-settings" element={ <ApplicationSettingsPage /> } />
      <Route path="/settings/cost-tariffs" element={ <ProtectedRoute element={ <CostsTariffsPage /> } requiredAuthorization="manage_energy_prices" /> } />
      <Route path="/settings/change-password" element={ <ChangePasswordPage /> } />
      <Route path="/settings/about" element={ <AboutPage /> } />
      <Route path="/settings/security" element={ <SecurityPage /> } />
      <Route path="*" element={ <NotFoundPage /> } />
    </Routes>

);


export default AppRoutes;