import { connect } from 'react-redux';
import { fetchGroupById } from '../../../actions/group.action';
import { fetchDeviceWallboxById } from '../../../actions/device.action';
import fetchStates from '../../../types/fetchState.types';

import React from 'react';
import Box from '../../shared/box/Box';
import Map from '../../shared/map/Map';
import Preloader from '../preloader/Preloader';
import InterfaceReducerGroup from '../../../intefaces/InterfaceReducerGroup';
import getLocalStorageItem from '../../../functions/getLocalStorageItem';
import { tr, trans } from '../../../translation/translations';
import InterfaceReducerDevice from '../../../intefaces/InterfaceReducerDevice';
import moment from 'moment';


interface DashboardInfoProps {
  group: InterfaceReducerGroup,
  device: InterfaceReducerDevice,
  infoScreen: string,
  infoId: string,
  fetchGroupById: (group_id: string) => Promise<void>,
  fetchDeviceWallboxById: (device_id: string) => Promise<void>
}


interface DashboardInfoState {
  infoScreen: string,
  infoId: string,
  dataLoaded: boolean,
  showMap: boolean,
  group: {
    group_title: string,
    group_color: string,
    users_total: number,
    devices_total: number,
    devices_active: number,
    energy_total: number,
    cost_total: number,
    chargers_geolocation: Array<any>,
  },
  device_wallbox: any
}


export class DashboardInfo extends React.Component<DashboardInfoProps, DashboardInfoState> {

  state = {
    infoScreen: this.props.infoScreen,
    infoId: this.props.infoId,
    dataLoaded: false,
    showMap: false,
    group: {
      group_title: '',
      group_color: '#ccc',
      users_total: 0,
      devices_total: 0,
      devices_active: 0,
      energy_total: 0,
      cost_total: 0,
      chargers_geolocation: []
    },
    device_wallbox: {
      device_title: '',
      device_serial: '',
      device_registered: '',
      energy_total: 0,
      cost_total: 0,
      device_lat: '',
      device_lng: '',
    }
  }


  async fetchGroupDetails() {
    try {
      this.setState({ dataLoaded: false });
      await this.props.fetchGroupById(this.props.infoId);
      if(this.props.group.status === fetchStates.success) {
        const { group } = this.props.group;
        const showMap = group.chargers_geolocation.length > 0;
        this.setState({ group, dataLoaded: true, showMap });
      }
    } catch (error) {
      console.error('Error fetching group details:', error);
    }
  }


  async fetchChargerDetails() {
    try {
      this.setState({ dataLoaded: false });
      await this.props.fetchDeviceWallboxById(this.props.infoId);
      if(this.props.device.status === fetchStates.success) {
        const { device_wallbox } = this.props.device;
        const showMap = device_wallbox.device_lat !== null && device_wallbox.device_lng !== null;
        const energy_total =  Math.floor(Math.random() * 100) + 1;
        const cost_total = (energy_total * getLocalStorageItem('account_energy_cost')).toFixed(2);
        this.setState({ device_wallbox: { ...device_wallbox, energy_total, cost_total }, dataLoaded: true, showMap });
      }
    } catch (error) {
      console.error('Error fetching charger details:', error);
    }
  }


  componentDidUpdate(prevProps:any) {
    if (prevProps.infoId !== this.props.infoId) {
      if(this.props.infoScreen === 'group') {
        this.fetchGroupDetails();
      }
      if(this.props.infoScreen === 'charger') {
        this.fetchChargerDetails();
      }
    }
  }


  renderGroupInfoScreen = () => {
    const { group, showMap } = this.state;
    return (
      <div className="box-body">
        <div className="charger-settings">
          <div className="charger-settings__section charger-settings__section--charger-settings">
            <div className="charger-settings__section-title">{ tr(trans.partialDashboardInfo.partialTitleGroup) }</div>
            <div className="charger-settings__item">
              <div className="charger-settings__title-label" style={{ backgroundColor: group.group_color }}>{ group.group_title }</div>
            </div>
            <div className="charger-settings__item"><strong>{ tr(trans.partialDashboardInfo.itemsLabel.users) }:</strong> { group.users_total }</div>
            <div className="charger-settings__item"><strong>{ tr(trans.partialDashboardInfo.itemsLabel.totalDevices) }:</strong> { group.devices_total }</div>
            <div className="charger-settings__item"><strong>{ tr(trans.partialDashboardInfo.itemsLabel.activeDevices) }:</strong> { group.devices_active }</div>
            <div className="charger-settings__item"><strong>{ tr(trans.partialDashboardInfo.itemsLabel.energyUsedToday) }:</strong> { group.energy_total } kWh</div>
            <div className="charger-settings__item"><strong>{ tr(trans.partialDashboardInfo.itemsLabel.cost) }:</strong> { group.cost_total } { getLocalStorageItem('account_currency') }</div>
          </div>
          <div className="charger-settings__section charger-settings__section--map">
            <div className="charger-settings__section-title">{ tr(trans.partialDashboardInfo.partialTitleLocationGroup) }</div>
          </div>


          {
            showMap && (
              <div className="charger-settings__section charger-settings__section--map">
                <div className="charger-settings__section-title">{ tr(trans.partialDashboardInfo.partialTitleLocationCharger) }</div>
                <Map positionChargers={group.chargers_geolocation} zoom={11} />
              </div>
            )
          }
        </div>
      </div>
    );
  }


  renderChargerInfoScreen = () => {
    const { device_wallbox, showMap } = this.state;
    return (
      <div className="box-body">
        <div className="charger-settings">
          <div className="charger-settings__section charger-settings__section--charger-settings">
            <div className="charger-settings__section-title">{ tr(trans.partialDashboardInfo.partialTitleCharger) }</div>
              <div className="charger-settings__item"><strong>{ tr(trans.partialDashboardInfo.itemsLabel.deviceTitle) }:</strong> { device_wallbox.device_title }</div>
              <div className="charger-settings__item"><strong>{ tr(trans.partialDashboardInfo.itemsLabel.energyUsedToday) }:</strong> { device_wallbox.energy_total } kWh</div>
              <div className="charger-settings__item"><strong>{ tr(trans.partialDashboardInfo.itemsLabel.cost) }:</strong> { device_wallbox.cost_total } { getLocalStorageItem('account_currency') }</div>
              <div className="charger-settings__item"><strong>{ tr(trans.partialDashboardInfo.itemsLabel.deviceSerial) }:</strong> { device_wallbox.device_serial }</div>
              <div className="charger-settings__item"><strong>{ tr(trans.partialDashboardInfo.itemsLabel.deviceRegistered) }:</strong> { moment(device_wallbox.device_registered).format('YYYY-MM-DD') }</div>
          </div>
          {
            showMap && (
              <div className="charger-settings__section charger-settings__section--map">
                <div className="charger-settings__section-title">{ tr(trans.partialDashboardInfo.partialTitleLocationCharger) }</div>
                <Map positionChargers={[{ device_id: device_wallbox.device_registered, device_lat: parseFloat(device_wallbox.device_lat), device_lng: parseFloat(device_wallbox.device_lng)} ]} zoom={11} />
              </div>
            )
          }
        </div>
      </div>
    );
  }


  renderDefaultScreen = () => {
    return (
      <div className="box-body">
        <Preloader preloaderVisible={true} preloaderSmall={true} />
      </div>
    );
  }


  renderInfoBox = () => {
    const { infoScreen, infoId } = this.props;

    // group box
    if(infoScreen === 'group' && infoId !== '' && this.state.dataLoaded === true ) {
      return this.renderGroupInfoScreen();
    }

    // charger box
    if(infoScreen === 'charger' && infoId !== '' && this.state.dataLoaded === true ) {
      return this.renderChargerInfoScreen();
    }

    // preloader box
    return this.renderDefaultScreen();
  }


  render() {
    return (
      <Box>
        { this.renderInfoBox() }
      </Box>
    );
  }
  
};



export default connect(
  ({ group, device }) => ({ group, device }),
  { fetchGroupById, fetchDeviceWallboxById }
)(DashboardInfo);