import { Provider } from 'react-redux';
import initStore from './store';
import ACEnergyApp from './ACEnergyApp';
import AppIsOnline from './AppIsOnline';
import { ReactNotifications } from 'react-notifications-component';
import './styles/index.css';


const store = initStore();


function App() {
  return (

      <Provider store={store}>
        <AppIsOnline />
        <ReactNotifications />
        <ACEnergyApp />
      </Provider>

  );
};


export default App;