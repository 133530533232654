import { useNavigate, Link } from 'react-router-dom';
import './notfound.css';
import TemplatePageFullwidth from '../../shared/template-page-fullwidth/TemplatePageFullwidth';
import { IconNotFound } from '../../shared/icons/IconsGeneral';
import { IconBack } from '../../shared/icons/IconsNavigation';
import { trans, tr } from '../../../translation/translations';


const NotFound: React.FC = () => {
  const navigate = useNavigate();
  return (
    <TemplatePageFullwidth>
      <div className="page page__notfound">
        <div className="auth-back-button">
          <span onClick={() => navigate(-1)}>
            <IconBack color="#000" size={40} />
          </span>
        </div>
        <div className="notfound__wrapper">
          <IconNotFound size={240} color="#000" />
          <h1>{ tr(trans.viewNotfound.pageTitle) }</h1>
          <p>{ tr(trans.viewNotfound.paragraph) }</p>
          <p>{ tr(trans.viewNotfound.action) }</p>
          <div className="notfound__actions">
            <Link className="button button--primary button--large" to="/">{ tr(trans.viewNotfound.buttonHome) }</Link>
            <span className="button button--primary button--large" onClick={() => navigate(-1)}>{ tr(trans.viewNotfound.buttonBack) }</span>
          </div>
        </div>
      </div>
    </TemplatePageFullwidth>

  )
};


export default NotFound;