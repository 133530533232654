import WithAuthentication from '../../hoc/auth/WithAuthentication';
import './settings.css';
import { tr, trans } from '../../../translation/translations';
import { IconBoxNavigationAboutApplication, IconBoxNavigationChangePassword, IconBoxNavigationCosts, IconBoxNavigationSecurity, IconBoxNavigationSettings } from '../../shared/icons/IconsBoxNavigation';
import TileNavigationSmall from '../../shared/tile-navigation-small/TileNavigationSmall';
import WithWs from '../../hoc/ws/WithWs';
import TemplatePageWithNavigation from '../../shared/template-page-navigation/TemplatePageWithNavigation';
import Authorization from '../../shared/authorization/Authorization';


const Settings: React.FC = () => {

  
  return (
    <TemplatePageWithNavigation title={ tr(trans.viewSettings.pageTitle) }>


      <nav className="box-navigation-group">

        <Authorization requiredAuthorization="manage_energy_prices">
          <TileNavigationSmall
            to="/settings/cost-tariffs"
            icon={ <IconBoxNavigationCosts size={24} color="#000" /> }
            title={ tr(trans.viewSettings.tileNavigationTitle.costsTariffs) }
          />
        </Authorization>


        <TileNavigationSmall
          to="/settings/application-settings"
          icon={ <IconBoxNavigationSettings size={24} color="#000" /> }
          title={ tr(trans.viewSettings.tileNavigationTitle.applicationSettings) }
        />

        <TileNavigationSmall
          to="/settings/change-password"
          icon={ <IconBoxNavigationChangePassword size={24} color="#000" /> }
          title={ tr(trans.viewSettings.tileNavigationTitle.changePassword) }
        />

        <TileNavigationSmall
          to="/settings/security"
          icon={ <IconBoxNavigationSecurity size={24} color="#000" /> }
          title={ tr(trans.viewSettings.tileNavigationTitle.security) }
        />

        <TileNavigationSmall
          to="/settings/about"
          icon={ <IconBoxNavigationAboutApplication size={24} color="#000" /> }
          title={ tr(trans.viewSettings.tileNavigationTitle.aboutApplication) }
        />

      </nav>

    </TemplatePageWithNavigation>
  );
};


export default WithAuthentication(WithWs(Settings));