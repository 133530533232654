import { EVENT, PRELOADER } from '../types/reducer.types';
import { errorNotification, successNotification } from '../functions/notifications';


const fetchEvent = (options: { endpoint: string, options: any, SUCCESS_TYPE: string }) => (dispatch:any) => {

  dispatch({ type: EVENT.FETCH });
  let authorizationHeader: any = [];
  dispatch({ type: PRELOADER.SHOW_PRELOADER });
  return fetch(`/api/event/${options.endpoint}`, options.options)
          .then(response => {
              authorizationHeader = response.headers.get('Authorization');
              return response.json();
            }
          )
          .then(json => {
            dispatch({ type: PRELOADER.HIDE_PRELOADER });
            if (json.errors !== undefined) {
              dispatch({ type: EVENT.FETCH_ERROR, ...json, authorizationHeader });
              json && json.errors && json.errors.map((item: {msg: string}) => {
                if(item.msg) {
                    errorNotification(item.msg);
                  }
                  return null;
              });
            } else {            
              dispatch({ type: options.SUCCESS_TYPE, ...json }, authorizationHeader);
              json && json.success && json.success.map((item: {msg: string}) => {
                if(item.msg) {
                  successNotification(item.msg);
                }
                return null
              });
            }
          })
          .catch(error => dispatch({
            type: EVENT.FETCH_ERROR, errors: [{ msg: error }], authorizationHeader
          }));
  }


  export const fetchEventCount = () => fetchEvent({
    endpoint: `count`,
    options: { 
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      },
      credentials: 'include' 
    },
    SUCCESS_TYPE: EVENT.FETCH_EVENTS_COUNT_SUCCESS
  });


  export const fetchEvents = (options: { limit: number, skip:number }) => fetchEvent({
    endpoint: `?limit=${options.limit}&skip=${options.skip}`,
    options: { 
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      },
      credentials: 'include' 
    },
    SUCCESS_TYPE: EVENT.FETCH_EVENTS_SUCCESS
  });


  export const fetchEventById = (device_id: string) => fetchEvent({
    endpoint: `${device_id}`,
    options: { 
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      },
      credentials: 'include' 
    },
    SUCCESS_TYPE: EVENT.FETCH_EVENT_BY_ID_SUCCESS
  });