import React from 'react';
import { RootState } from '../../../reducers';
import { connect } from 'react-redux';
import Preloader from '../../shared/preloader/Preloader';


const WithPreloader = (Component: any) => {

  interface WithPreloaderProps {
    preloader: any,
  }
  

  class WithPreloader extends React.Component<WithPreloaderProps> {

    resolveComponent () {
      return <>
        <Preloader preloaderVisible={this.props.preloader.preloaderVisible} />
        <Component {...this.props} />;
      </>
    }

    render() {
      return this.resolveComponent();
    }
  }

  return connect(
    ({ preloader }: RootState) => ({ preloader }),
    {}
  )(WithPreloader);

}


export default WithPreloader;