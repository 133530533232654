import React from 'react';
import WithRouter from '../../hoc/router/WithRouter';
import { Navigate, Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { RootState } from '../../../reducers';
import fetchStates from '../../../types/fetchState.types';
import { forgotPassword  } from '../../../actions/auth.action';

import TemplatePageFullwidth from '../../shared/template-page-fullwidth/TemplatePageFullwidth';
import classnames from 'classnames';
import { IconBack } from '../../shared/icons/IconsNavigation';
import { IconLogoDarkDesktop } from '../../shared/icons/IconsLogo';
import Language from '../../shared/language/Language';
import { tr, trans } from '../../../translation/translations';
import { IconWarning } from '../../shared/icons/IconsForm';
import InterfaceReducerAuth from '../../../intefaces/InterfaceReducerAuth';



interface ForgotPasswordProps {
  router: {navigate: any},
  auth: InterfaceReducerAuth,
  forgotPassword: (forgot_email: string) => Promise<void>,
}


interface ForgotPasswordState {
  formSubmitted: boolean,
  forgot_email: string,
  formError: boolean
}

export class ForgotPassword extends React.Component<ForgotPasswordProps, ForgotPasswordState> {


  state = {
    formSubmitted: false,
    forgot_email: '',
    formError: false
  }


  handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState({ formSubmitted: true });
    const { forgot_email } = this.state;
    await this.props.forgotPassword(forgot_email);
    if(this.props.auth.status === fetchStates.success) {
      this.setState({ forgot_email: '', formSubmitted: false });
      this.props.router.navigate(`/password-reset`);
    }
    if(this.props.auth.status === fetchStates.error) {
      this.setState({ formError: true });
      const self = this;
      setTimeout(function() { 
        if(self.state.formError === true) {
          self.setState({ formError: false });
        }
      }, 250);
    }
  }


  render() {

    const { formSubmitted, forgot_email, formError } = this.state;
    const { authenticated, fields, status } = this.props.auth;
    
    return authenticated ? <Navigate to="/" /> : (
      <TemplatePageFullwidth>
        <Language />
        <div className="page page--auth page__forgot-password">
          <div className="auth-back-button">
            <Link to="/">
              <IconBack color="#000" size={40} />
            </Link>
          </div>
          <div className={classnames('form-wrapper form-wrapper--auth', { 'form-error': formError })}>
            <div className="logo logo--auth">
              <IconLogoDarkDesktop width={240} height={80} />
            </div>
            <h1 className="title title--main title--auth">{ tr(trans.viewForgotPassword.pageTitle) }</h1>
            <p className="paragraph paragraph--auth">{ tr(trans.viewForgotPassword.paragraphInstructions) }</p>

            <form className="form form--auth" onSubmit={this.handleOnSubmit}>

              <div className="form-group form-group--auth">
                <label className={classnames('label label--auth', { 'label--error': fields && fields.includes('forgot_email') })} htmlFor="login__email">
                  { fields && fields.includes('forgot_email') && <IconWarning size={16} color="#fff" /> } 
                  { tr(trans.viewForgotPassword.form.labelEmail) }
                </label>
                <div className="input-group input-group--auth">
                  <input
                    id="forgot_email"
                    type="email"
                    autoComplete="login_email"
                    value={forgot_email}
                    onChange={e => this.setState({ forgot_email: e.target.value })}
                    className={classnames('input input--auth', { 'input--error': fields && fields.includes('forgot_email') })}
                  />
                </div>
              </div>


              <div className="form-group form-group--auth">
                <input 
                  type="submit" 
                  className="button button--auth button--large button-margin--bottom" 
                  value={ tr(trans.viewForgotPassword.form.buttonResetPassword) } 
                  disabled={formSubmitted && status === 'fetching' ? true : false}
                />
              </div>

            </form>
          </div>
        </div>
      </TemplatePageFullwidth>
    )
  }
};


export default WithRouter(connect(
  ({ auth }: RootState) => ({ auth }),
  { forgotPassword }
)(ForgotPassword));