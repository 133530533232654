import React from 'react';
import { connect } from 'react-redux';
import fetchStates from '../../../types/fetchState.types';
import { fetchGroupById, deleteGroupById } from '../../../actions/group.action';

import WithAuthentication from '../../hoc/auth/WithAuthentication';
import WithRouter from '../../hoc/router/WithRouter';
import WithWs from '../../hoc/ws/WithWs';

import TemplatePageWithNavigation from '../../shared/template-page-navigation/TemplatePageWithNavigation';
import './groups-delete.css';
import { tr, trans } from '../../../translation/translations';
import classnames from 'classnames';
import InterfaceReducerGroup from '../../../intefaces/InterfaceReducerGroup';
import Box from '../../shared/box/Box';
import WithPreloader from '../../hoc/preloader/WithPreloader';


interface GroupDeleteProps {
  router: { location: string, navigate: (to: any) => any, params: { group_id: string } },
  group: InterfaceReducerGroup,
  fetchGroupById: (group_id: string) => Promise<void>,
  deleteGroupById: (group: any) => Promise<void>
}


interface GroupDeleteState {
  group: {
    group_title: string,
    group_id: string
  },
  formSubmitted: boolean,
  formError: boolean
}


export class GroupDelete extends React.Component<GroupDeleteProps, GroupDeleteState> {


  state = {
    group: {
      group_title: '',
      group_id: ''
    },
    formSubmitted: false,
    formError: false
  }


  componentDidMount(): void {
    this.getGroupById();
  }


  getGroupById = async () => {
    await this.props.fetchGroupById(this.props.router.params.group_id);
    if(this.props.group.status === fetchStates.success) {
      const { group } = this.props.group;
      this.setState({ group });
    }
  }


  handleOnDeviceDeleteSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState({ formSubmitted: true });
    await this.props.deleteGroupById(this.state.group);
    this.setState({ formSubmitted: false });
    if(this.props.group.status === fetchStates.error) {
      this.setState({ formError: true });
      const self = this;
      setTimeout(function() { 
        if(self.state.formError === true) {
          self.setState({ formError: false });
        }
      }, 250);
    }
    if(this.props.group.status === fetchStates.success) {
      this.props.router.navigate('/groups');
    }
    this.setState({ formSubmitted: false });
  }


  render() {

    const { formSubmitted, formError } = this.state;
    const { status } = this.props.group;

    return (
      <TemplatePageWithNavigation title={ tr(trans.viewGroupsDelete.pageTitle) } buttonBack >
        <Box>
          <form className={classnames('form', { 'form-error': formError })} onSubmit={this.handleOnDeviceDeleteSubmit}>
            <div className="form-section">

              <div className="form-group">
                <input 
                  type="submit" 
                  value={ tr(trans.viewGroupsDelete.form.buttonDeleteGroup) } 
                  className="button button--primary button--small"
                  disabled={ formSubmitted && status === 'fetching' ? true : false }
                />
              </div>

            </div>
          </form>
        </Box>
      </TemplatePageWithNavigation>
    );
  }
};


export default WithAuthentication(WithWs(WithPreloader(WithRouter(connect(
  ({ group }) => ({ group }),
  { fetchGroupById, deleteGroupById }
)(GroupDelete)))));