import AppRoutes from './AppRoutes';


const ACEnergyApp: React.FC = () => {
  return (
    <AppRoutes />
  );
};


export default ACEnergyApp;