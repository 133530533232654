import React from 'react';
import './box-titlebar.css';


interface BoxTitlebarProps {
  title: string | JSX.Element | JSX.Element[],
  buttons?: Array<object>,
}


const BoxTitlebar: React.FC<BoxTitlebarProps> = ({ title, buttons }) => {
  return (
    <div
      className="box-titlebar"
    >
      <h2 className="box-titlebar__title">{ title }</h2>
      <div className="box-titlebar_buttons">
        {
          buttons && buttons.map((button:any) => button)
        }
      </div>
    </div>
  );
};


export default BoxTitlebar;