import React from 'react';
import './toolbar.css';
// import { IconCarretDown, IconNotificationBell } from '../icons/IconsGeneral';
import { IconBack } from '../icons/IconsNavigation';
import { useNavigate } from 'react-router';
// import { Link } from 'react-router-dom';


interface ToolbarProps {
  title: string,
  onMenuButtonClick: any,
  buttonBack?: boolean
}


const Toolbar: React.FC<ToolbarProps> = ({ title, onMenuButtonClick, buttonBack }) => {

  const navigate = useNavigate();
  
  return (
    <div className="toolbar">
      <div className="toolbar__left-section">
        <div className="toolbar__menu-button" onClick={onMenuButtonClick}>
          <div className="menu-button__stripe" />
          <div className="menu-button__stripe" />
          <div className="menu-button__stripe" />
        </div>
        {
          buttonBack && (
            <div className="toolbar__return-button" onClick={() => navigate(-1)}>
              <IconBack color="#000" size={36} />
            </div>
          )
        }
        <h1 className="tooblar__page-title">{ title }</h1>
      </div>
      <div className="toolbar__right-section">
        {
          /*
            <Link to="/events" className="toolbar__notifications">
              <IconNotificationBell size={24} color="#666" />
            </Link>
            <Link to="/settings" className="toolbar__account">
              Settings <IconCarretDown size={10} />
            </Link>
          */
        }
      </div>
    </div>
  )
}


export default Toolbar;