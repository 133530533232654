import React from 'react';
import { connect } from 'react-redux';
import fetchStates from '../../../types/fetchState.types';
import { fetchDeviceWallboxById, fetchDeviceWallboxChargingEventsByDeviceSerial } from '../../../actions/device.action';

import WithAuthentication from '../../hoc/auth/WithAuthentication';
import WithRouter from '../../hoc/router/WithRouter';
import WithWs from '../../hoc/ws/WithWs';

import TemplatePageWithNavigation from '../../shared/template-page-navigation/TemplatePageWithNavigation';
import InterfaceReducerDevice from '../../../intefaces/InterfaceReducerDevice';
import Box from '../../shared/box/Box';
import WithPreloader from '../../hoc/preloader/WithPreloader';
import { tr, trans } from '../../../translation/translations';
import Map from '../../shared/map/Map';
import BoxTitlebar from '../../shared/box-titlebar/BoxTitlebar';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { IconTablesMore } from '../../shared/icons/IconsTables';
import TableFooter from '../../shared/table-footer/TableFooter';
import getLocalStorageItem from '../../../functions/getLocalStorageItem';




interface DeviceEditProps {
  router: { location: string, navigate: (to: any) => any, params: { device_id: string } },
  device: InterfaceReducerDevice,
  wsConnected: boolean,
  wsData: any,
  dataCommandEmit: any
  fetchDeviceWallboxById: (device_id: string) => Promise<void>,
  fetchDeviceWallboxChargingEventsByDeviceSerial: (options: { device_id: string, limit: number, skip: number }) => Promise<void>,
}


interface DeviceEditState {
  device_wallbox: {
    device_title: string,
    device_serial: string,
    group_id: string,
    device_data: any
  },
  requestedDeviceData: boolean,
  device_wallbox_charging_events: Array<any>,
  device_wallbox_charging_events_count: number,
  currentPage: number,
  perPage: number,
}


export class DeviceEdit extends React.Component<DeviceEditProps, DeviceEditState> {


  state = {
    device_wallbox: {
      device_title: '',
      device_serial: '',
      device_id: '',
      group_id: '',
      device_lng: '',
      device_lat: '',
      device_data: {
        PWR_out: {
          STch: '00'
        },
        SEC: {
          DET: 'N'
        },
      }
    },
    requestedDeviceData: false,
    device_wallbox_charging_events: [],
    device_wallbox_charging_events_count: 0,
    currentPage: 0,
    perPage: 10,
  }


  componentDidUpdate(prevProps:any) {
    // incomming data for device_wallbox from redux state
    if (this.props.device.device_wallbox !== prevProps.device.device_wallbox) {
      const { device_wallbox } = this.props.device;
      this.setState({ device_wallbox: { ...device_wallbox }});
    }
    // fire only after wsConnected
    if(this.props.wsConnected === true) {
      // checking if already requested the data once
      if(this.state.requestedDeviceData === false) {
        this.setState({ requestedDeviceData: true });
        this.getWallboxDeviceById();
        this.dataDeviceRequest(this.state.device_wallbox.device_serial);
      }
    }
  }


  getWallboxDeviceById = async () => {
    await this.props.fetchDeviceWallboxById(this.props.router.params.device_id);
    if(this.props.device.status === fetchStates.success) {
      const { device_wallbox } = this.props.device;
      this.setState({ device_wallbox });
      this.dataDeviceRequest(this.state.device_wallbox.device_serial);
      this.getChargingEvents(this.state.device_wallbox.device_id);
    }
  }


  getChargingEvents = async (device_id: string) => {
    await this.props.fetchDeviceWallboxChargingEventsByDeviceSerial({ device_id, limit: 10, skip: 0 });
    if(this.props.device.status === fetchStates.success) {
      const { device_wallbox_charging_events, device_wallbox_charging_events_count } = this.props.device;
      this.setState({ device_wallbox_charging_events, device_wallbox_charging_events_count });
    }
  }


  // WS: requesting data
  dataDeviceRequest = (device_serial: string) => {
    const data = { 
      device_serial,
      ts: Math.floor(Date.now() / 1000),
      message_type: 'device',
      message_command: 'data',
      message_value: [ "DEV_I", "PWR_I", "PWR_in", "PWR_out", "PWR_aux", "SEC", "Ulog", "WiFi", "SLOT", "ERRW", "CS" ] 
    };
    this.props.dataCommandEmit(data);
  }


  // WS: turning the button on/off
  pushDataCommandEmit = (data:any) => {
    this.props.dataCommandEmit(data);
  }


  updatePagination = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    this.setState({ perPage: parseInt(e.target.value), currentPage: 0  });
    this.reloadPage({ device_id: this.state.device_wallbox.device_id, perPage: parseInt(e.target.value), currentPage: 0  });
  }


  prevPage = (prevPage: number) => {
    this.setState({ currentPage: prevPage });
    this.reloadPage({ device_id: this.state.device_wallbox.device_id, currentPage: prevPage, perPage: this.state.perPage });
  }


  nextPage = (nextPage: number) => {
    this.setState({ currentPage: nextPage });
    this.reloadPage({ device_id: this.state.device_wallbox.device_id, currentPage: nextPage, perPage: this.state.perPage });
  }


  reloadPage = async (options: { device_id: string, perPage: number, currentPage: number }) => {
    await this.props.fetchDeviceWallboxChargingEventsByDeviceSerial({ device_id: options.device_id, limit: options.perPage, skip: options.perPage * options.currentPage });
    if(this.props.device.status === fetchStates.success) {
      const { device_wallbox_charging_events, device_wallbox_charging_events_count } = this.props.device;
      this.setState({ device_wallbox_charging_events, device_wallbox_charging_events_count });
    }
  }


  render() {

    const { device_wallbox, device_wallbox_charging_events, device_wallbox_charging_events_count, currentPage, perPage } = this.state;

    return (
      <TemplatePageWithNavigation title={ device_wallbox.device_title } buttonBack>

        {
          (device_wallbox.device_lat !== null && device_wallbox.device_lng !== null) ?  (
            <Box>
              <Map zoom={12} positionChargers={[{ ...device_wallbox }]} />
            </Box>
          ) : <></>
        }



        <Box>
          <div>
            <strong>VEHICLE DETECTED:</strong> { device_wallbox.device_data && device_wallbox.device_data.SEC.DET }
          </div>
          <div>
            <strong>CHARGING STATUS:</strong> { device_wallbox.device_data && device_wallbox.device_data.PWR_out.STch }
          </div>   

          <div>
            { 
              device_wallbox.device_data && device_wallbox.device_data.PWR_out.STch === '02' ? (
                <>
                  <div>Device is currently charging</div>
                  <div>
                    <button className="button button--primary button--small" onClick={() => this.pushDataCommandEmit({ "device_serial": device_wallbox.device_serial, "message_type": "device", "message_command": "charge", "message_value": 0 })}>
                      { tr(trans.viewDevicesWallbox.tableButton.stopCharging) }
                    </button> 
                  </div>
                </>
              ) : (
                <>
                  <div>Device is currently NOT charging</div>
                  <div>
                    <button className="button button--primary button--small" onClick={() => this.pushDataCommandEmit({ "device_serial": device_wallbox.device_serial, "message_type": "device", "message_command": "charge", "message_value": 1 })}>
                      { tr(trans.viewDevicesWallbox.tableButton.startCharging) }
                    </button>
                  </div>
                </>
              )
            }
          </div>
        </Box>


        {
          device_wallbox.device_data && (
            <Box>
              <>
                { JSON.stringify(device_wallbox.device_data, null, 2) }
              </>   
            </Box>
          )
        }



        <Box>
          <BoxTitlebar title="Charging events" />
          <div className="table">
          <div className="row header">
            <div className="cell">ID</div>
            <div className="cell">{ tr(trans.partialDashboardChargingEvents.tableHeader.deviceSerialNo) }</div>
            <div className="cell">{ tr(trans.partialDashboardChargingEvents.tableHeader.chargingStarted) }</div>
            <div className="cell">{ tr(trans.partialDashboardChargingEvents.tableHeader.chargingEnded) }</div>
            <div className="cell">{ tr(trans.partialDashboardChargingEvents.tableHeader.chargingTime) }</div>
            <div className="cell">{ tr(trans.partialDashboardChargingEvents.tableHeader.energyConsumed) }</div>
            <div className="cell">{ tr(trans.partialDashboardChargingEvents.tableHeader.TotalCost) }</div>
            <div className="cell">{ tr(trans.partialDashboardChargingEvents.tableHeader.error) }</div>
            <div className="cell cell-button"></div>
          </div>
          { device_wallbox_charging_events && device_wallbox_charging_events.map((event: any) => {
            return (
              <div className="row" key={ event.event_id }>
                <div className="cell" data-title="ID">
                  { event.event_id }
                </div>
                <div className="cell" data-title={ tr(trans.partialDashboardChargingEvents.tableHeader.deviceSerialNo) }>
                  { event.device_serial }
                </div>
                <div className="cell" data-title={ tr(trans.partialDashboardChargingEvents.tableHeader.chargingStarted) }>
                  { moment(event.event_start_tst).format('DD MMM - HH:mm') }
                </div>
                <div className="cell" data-title={ tr(trans.partialDashboardChargingEvents.tableHeader.chargingEnded) }>
                  { moment(event.event_end_tst).format('DD MMM - HH:mm') }
                </div>
                <div className="cell" data-title={ tr(trans.partialDashboardChargingEvents.tableHeader.chargingTime) }>
                  { moment.utc(event.event_charging_time_s*1000).format('HH:mm:ss') }
                </div>
                <div className="cell" data-title={ tr(trans.partialDashboardChargingEvents.tableHeader.energyConsumed) }>
                  { event.event_energy_consumed_kwh } kWh
                </div>
                <div className="cell" data-title={ tr(trans.partialDashboardChargingEvents.tableHeader.TotalCost) }>
                  { (event.event_energy_consumed_kwh * getLocalStorageItem('account_energy_cost')).toFixed(2) } { getLocalStorageItem('account_currency') }
                </div>
                <div className="cell" data-title={ tr(trans.partialDashboardChargingEvents.tableHeader.error) }>
                  { event.event_error }
                </div>
                <div className="cell">
                  <Link className="table-icon" to={`/charging-event/${ event.event_id }`}>
                    <IconTablesMore color="#666" size={24} />
                  </Link>
                </div>
              </div>
            );
          })
        }

        </div>
        <TableFooter 
          currentPage={ currentPage }
          perPage={ perPage }
          itemsCount={ device_wallbox_charging_events_count }
          prevPage={() => this.prevPage(currentPage - 1)}
          nextPage={() => this.nextPage(currentPage + 1)}
          updatePagination={this.updatePagination}
        />
        </Box>


      </TemplatePageWithNavigation>
    );
  }
};


export default WithAuthentication(WithWs(WithPreloader(WithRouter(connect(
  ({ device }) => ({ device }),
  { fetchDeviceWallboxById, fetchDeviceWallboxChargingEventsByDeviceSerial }
)(DeviceEdit)))));