import { GROUP } from '../types/reducer.types';
import fetchStates from '../types/fetchState.types';
import InterfaceReducerGroup from '../intefaces/InterfaceReducerGroup';


const DEFAULT_GROUP_STATE: InterfaceReducerGroup = { 
  status: '',
  fields: [],
  msg: [],
  groups_count: 0,
  groups: [],
  groups_account: [],
  group: [],
  groups_overview: [],
  groups_overview_count: 0
}


const group = (state = DEFAULT_GROUP_STATE, action: any) => {
  if(action.authorizationHeader) {
    localStorage.setItem('accessToken', action.authorizationHeader);
  }
  switch(action.type) {
    case GROUP.FETCH:
      return { 
        ...state, 
        status: fetchStates.fetching,
        msg: [],
        fields: [],
      };
    case GROUP.FETCH_ERROR:
      return { 
        ...state, 
        status: fetchStates.error,
        msg: action.errors,
        fields: action.errors && action.errors.map((error: {path: string}) => error.path !== undefined && error.path.toString()),
      };
    case GROUP.FETCH_GROUPS_COUNT_SUCCESS:
      return {
        ...state,
        groupd_count: action.groups_count,
        status: fetchStates.success,
      };
    case GROUP.FETCH_GROUPS_SUCCESS:
      return {
        ...state,
        groups: action.groups,
        groups_count: action.groups_count,
        status: fetchStates.success,
      };
    case GROUP.FETCH_GROUPS_ACCOUNT_SUCCESS:
      return {
        ...state,
        groups_account: action.groups_account,
        status: fetchStates.success,
      };
    case GROUP.FETCH_GROUP_BY_ID_SUCCESS:
      return {
        ...state,
        group: action.group,
        status: fetchStates.success,
      };
    case GROUP.FETCH_INSERT_GROUP_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
        fields: action.errors && action.errors.map((error: {path: string}) => error.path !== undefined && error.path.toString()),
      };
    case GROUP.FETCH_UPDATE_GROUP_BY_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
        fields: action.errors && action.errors.map((error: {path: string}) => error.path !== undefined && error.path.toString()),
      };
    case GROUP.FETCH_DELETE_GROUP_BY_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success
      };
    case GROUP.FETCH_GROUPS_OVERVIEW_SUCCESS:
      return {
        ...state,
        groups_overview: action.groups_overview,
        groups_overview_count: action.groups_overview_count,
        status: fetchStates.success,
        msg: action.success
      }
    default:
      return state;
  }
};


export default group;