import React from 'react';
import { connect } from 'react-redux';
import fetchStates from '../../../types/fetchState.types';
import { fetchGroupsOverview } from '../../../actions/group.action';
import { fetchDeviceWallboxOverview } from '../../../actions/device.action';
import { tr, trans } from '../../../translation/translations';
import InterfaceReducerGroup from '../../../intefaces/InterfaceReducerGroup';
import Box from '../box/Box';
import BoxTitlebar from '../box-titlebar/BoxTitlebar';
import TableFooter from '../table-footer/TableFooter';
import getLocalStorageItem from '../../../functions/getLocalStorageItem';
import { IconArrowLeft, IconCarretRight, IconNavDevices, IconNavGroups } from '../icons/IconsNavigation';
import moment from 'moment';
import InterfaceReducerDevice from '../../../intefaces/InterfaceReducerDevice';


interface DashboardOverviewProps {
  device: InterfaceReducerDevice,
  group: InterfaceReducerGroup,
  fetchGroupsOverview: (options: { limit: number, skip: number }) => Promise<void>,
  fetchDeviceWallboxOverview: (options: { group_id: string, limit: number, skip: number }) => Promise<void>,
  onItemClick: any,
}


interface DashboardOverviewState {
  groups_overview: Array<any>,
  groups_overview_count: number,
  devices_wallbox_overview: Array<any>,
  devices_wallbox_overview_count: number,
  currentPage: number,
  perPage: number,
  infoBlockType: string,
  infoBlockId: string,
  currentScreen: string,
  currentTitle: string,
  currentGroupId: string
}


export class DashboardOverview extends React.Component<DashboardOverviewProps, DashboardOverviewState> {


  state = {
    groups_overview: [],
    groups_overview_count: 0,
    devices_wallbox_overview: [],
    devices_wallbox_overview_count: 0,
    perPage: 5,
    currentPage: 0,
    infoBlockType: 'group',
    infoBlockId: '',
    currentScreen: 'groups',
    currentTitle: '',
    currentGroupId: ''
  }


  componentDidMount(): void {
    this.getAllWallboxDevices();
  }


  getAllWallboxDevices = async () => {
    const { perPage, currentPage } = this.state;
    this.reloadPage({ group_id: this.state.currentGroupId, perPage, currentPage });
  }


  updatePagination = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    this.setState({ perPage: parseInt(e.target.value), currentPage: 0  });
    this.reloadPage({ group_id: this.state.currentGroupId, perPage: parseInt(e.target.value), currentPage: 0  });
  }


  prevPage = (prevPage: number) => {
    this.setState({ currentPage: prevPage });
    this.reloadPage({ group_id: this.state.currentGroupId, currentPage: prevPage, perPage: this.state.perPage });
  }


  nextPage = (nextPage: number) => {
    this.setState({ currentPage: nextPage });
    this.reloadPage({ group_id: this.state.currentGroupId, currentPage: nextPage, perPage: this.state.perPage });
  }


  reloadPage = async (options: { group_id: string, perPage: number, currentPage: number }) => {
    const { currentScreen } = this.state;
    if(currentScreen === 'groups') {
      await this.props.fetchGroupsOverview({ limit: options.perPage, skip: options.perPage * options.currentPage });
      if(this.props.group.status === fetchStates.success) {
        const { groups_overview, groups_overview_count } = this.props.group;
        this.setState({ groups_overview, groups_overview_count });
        if(groups_overview.length > 0) {
          this.handleChangeInfoBoxContent({ infoBlockType: 'group', infoBlockId: groups_overview[0].group_id });
        }
      }
    }
    if(currentScreen === 'chargers') {
      await this.props.fetchDeviceWallboxOverview({ group_id: options.group_id, limit: options.perPage, skip: options.perPage * options.currentPage });
      if(this.props.group.status === fetchStates.success) {
        const { devices_wallbox_overview, devices_wallbox_overview_count } = this.props.device;
        this.setState({ devices_wallbox_overview, devices_wallbox_overview_count });
        if(devices_wallbox_overview.length > 0) {
          this.handleChangeInfoBoxContent({ infoBlockType: 'charger', infoBlockId: devices_wallbox_overview[0].device_id });
        }
      }
    }
  }


  handleChangeInfoBoxContent = (options: { infoBlockType: string, infoBlockId: string }) => {
    const { infoBlockType, infoBlockId } = options;
    this.setState({ infoBlockType, infoBlockId });
    this.props.onItemClick({ infoBlockType, infoBlockId });
  }


  handleLoadGroupChargers = (options: { group_id: string, group_title: string }) => {
    const { group_id, group_title } = options;
    this.setState({ currentScreen: 'chargers', currentTitle: group_title, currentGroupId: group_id }, () => this.reloadPage({ group_id, currentPage: 0, perPage: 5 }));
  }


  resetScreen = () => {
    this.setState({ currentScreen: 'groups', currentPage: 0, perPage: 5 });
    this.handleChangeInfoBoxContent({ infoBlockType: 'group', infoBlockId: this.state.currentGroupId });
  }



  renderScreenGroups = () => {
    const { groups_overview, groups_overview_count, perPage, currentPage, infoBlockId } = this.state;
    return (
      <>
        <BoxTitlebar title={ `${tr(trans.partialDashboardOverview.partialTitleGroups)}` } />
        <div className="table">
          <div className="row header">
            <div className="cell cell-buttons"></div>
            <div className="cell">{ tr(trans.partialDashboardOverview.tableHeader.groupName) }</div>
            <div className="cell">{ tr(trans.partialDashboardOverview.tableHeader.wallboxCount) }</div>
            <div className="cell">{ tr(trans.partialDashboardOverview.tableHeader.activeWallbox) }</div>
            <div className="cell">{ tr(trans.partialDashboardOverview.tableHeader.energyConsumed) }</div>
            <div className="cell">{ tr(trans.partialDashboardOverview.tableHeader.eventsErrors) }</div>
            <div className="cell">{ tr(trans.partialDashboardOverview.tableHeader.energyCost) }</div>
            <div className="cell">{ tr(trans.partialDashboardOverview.tableHeader.registeredUsers) }</div>
          </div>
            { groups_overview && groups_overview.map((group: any) => {
              const energy = Math.floor((Math.random() * 2000) + 1);
                return (
                  <div className="row" key={ group.group_id }>
                    <div className="cell cell-icon cell-icon--clickable">
                      <span className={group.group_id === infoBlockId ? "dashboard-navigation-icon dashboard-navigation-icon--selected" : "dashboard-navigation-icon"} onClick={() => this.handleChangeInfoBoxContent({ infoBlockType: 'group', infoBlockId: group.group_id })}>
                        <IconCarretRight color="#fff" size={21} />
                      </span>
                    </div>
                    <div className="cell" data-title={ tr(trans.partialDashboardOverview.tableHeader.groupName) }>
                      <span className="table-identity-icon">
                        <IconNavGroups color="#666" size={16} />
                      </span>
                      <span className="table-label table-label--text" style={{ background: group.group_color }}>{ group.group_title }</span> 
                    </div>
                    <div className="cell" data-title={ tr(trans.partialDashboardOverview.tableHeader.wallboxCount) }>
                      { Math.floor((Math.random() * 25) + 1) }
                    </div>
                    <div className="cell" data-title={ tr(trans.partialDashboardOverview.tableHeader.activeWallbox) }>
                      { Math.floor((Math.random() * 14) + 1) }
                    </div>
                    <div className="cell" data-title={ tr(trans.partialDashboardOverview.tableHeader.energyConsumed) }>
                      { energy } kWh
                    </div>
                    <div className="cell" data-title={ tr(trans.partialDashboardOverview.tableHeader.eventsErrors) }>
                      { Math.floor((Math.random() * 4) + 1) }/{ Math.floor((Math.random() * 1)) }
                    </div>
                    <div className="cell" data-title={ tr(trans.partialDashboardOverview.tableHeader.energyCost) }>
                      { (energy * getLocalStorageItem('account_energy_cost')).toFixed(2) } { getLocalStorageItem('account_currency') }
                    </div>
                    <div className="cell" data-title={ tr(trans.partialDashboardOverview.tableHeader.registeredUsers) }>
                      { Math.floor((Math.random() * 11) + 1) }
                    </div>
                  </div>
                );
              })
            }
        </div>
        <TableFooter 
          currentPage={ currentPage }
          perPage={ perPage }
          itemsCount={ groups_overview_count }
          prevPage={() => this.prevPage(currentPage - 1)}
          nextPage={() => this.nextPage(currentPage + 1)}
          updatePagination={this.updatePagination}
        />
      </>
    )
  }


  renderScreenChargers = () => {
    const { currentTitle, devices_wallbox_overview, devices_wallbox_overview_count, perPage, currentPage, infoBlockId } = this.state;
    return (
      <>
        <BoxTitlebar title={ <><span onClick={() => this.resetScreen()} className="navigation"><IconArrowLeft size={16} color="#fff" /></span><span className="title-with-navigation">{currentTitle}</span></> } />
        <div className="table">
          <div className="row header">
            <div className="cell cell-icon"></div>
            <div className="cell">{ tr(trans.viewDevicesWallbox.tableHeader.title) }</div>
            <div className="cell">{ tr(trans.viewDevicesWallbox.tableHeader.serialNo) }</div>
            <div className="cell">{ tr(trans.viewDevicesWallbox.tableHeader.group) }</div>
            <div className="cell">{ tr(trans.viewDevicesWallbox.tableHeader.registered) }</div>
          </div>
            { devices_wallbox_overview && devices_wallbox_overview.map((device: any) => {
                return (

                  <div className="row" key={ device.device_serial }>
                    <div className="cell cell-icon cell-icon--clickable">
                      <span className={device.device_id === infoBlockId ? "table-icon table-icon--selected" : "table-icon"} onClick={() => this.handleChangeInfoBoxContent({ infoBlockType: 'charger', infoBlockId: device.device_id })}>
                        <IconNavDevices color="#666" size={16} />
                      </span>
                    </div>
                    <div className="cell" data-title={ tr(trans.viewDevicesWallbox.tableHeader.title) }>
                      { device.device_title }
                    </div>
                    <div className="cell" data-title={ tr(trans.viewDevicesWallbox.tableHeader.serialNo) }>
                      { device.device_serial }
                    </div>
                    <div className="cell" data-title={ tr(trans.viewDevicesWallbox.tableHeader.group) }>
                      <span className="table-label table-label--text" style={{ background: device.group_color }}>{ device.group_title }</span>
                      </div>
                    <div className="cell" data-title={ tr(trans.viewDevicesWallbox.tableHeader.registered) }>
                      { moment(device.device_registered).format('YYYY-MM-DD') }
                    </div>
                  </div>
                );
              })
            }
        </div>
        <TableFooter 
          currentPage={ currentPage }
          perPage={ perPage }
          itemsCount={ devices_wallbox_overview_count }
          prevPage={() => this.prevPage(currentPage - 1)}
          nextPage={() => this.nextPage(currentPage + 1)}
          updatePagination={this.updatePagination}
        />
      </>
    );
  }
  

  render() {
    const { currentScreen } = this.state;

    if(currentScreen === 'groups') {
      return ( <Box>{ this.renderScreenGroups() }</Box> );
    }

    if(currentScreen === 'chargers') {
      return ( <Box>{ this.renderScreenChargers() }</Box> );
    }

  }
};


export default connect(
  ({ group, device }) => ({ group, device }),
  { fetchGroupsOverview, fetchDeviceWallboxOverview }
)(DashboardOverview);