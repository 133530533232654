import React from 'react';
import { connect } from 'react-redux';
import fetchStates from '../../../types/fetchState.types';
import { fetchDeviceWallboxById, deleteDeviceWallboxById } from '../../../actions/device.action';

import WithAuthentication from '../../hoc/auth/WithAuthentication';
import WithRouter from '../../hoc/router/WithRouter';
import WithWs from '../../hoc/ws/WithWs';

import TemplatePageWithNavigation from '../../shared/template-page-navigation/TemplatePageWithNavigation';
import { tr, trans } from '../../../translation/translations';
import classnames from 'classnames';
import InterfaceReducerDevice from '../../../intefaces/InterfaceReducerDevice';
import Box from '../../shared/box/Box';
import WithPreloader from '../../hoc/preloader/WithPreloader';


interface DeviceDeleteProps {
  router: { location: string, navigate: (to: any) => any, params: { device_id: string } },
  device: InterfaceReducerDevice,
  fetchDeviceWallboxById: (device_id: string) => Promise<void>,
  deleteDeviceWallboxById: (device_wallbox: any) => Promise<void>
}


interface DeviceDeleteState {
  device_wallbox: {
    device_title: string,
    device_serial: string,
  },
  formSubmitted: boolean,
  formError: boolean
}


export class DeviceDelete extends React.Component<DeviceDeleteProps, DeviceDeleteState> {


  state = {
    device_wallbox: {
      device_title: '',
      device_serial: ''
    },
    formSubmitted: false,
    formError: false
  }


  componentDidMount(): void {
    this.getWallboxDeviceById();
  }


  getWallboxDeviceById = async () => {
    await this.props.fetchDeviceWallboxById(this.props.router.params.device_id);
    if(this.props.device.status === fetchStates.success) {
      const { device_wallbox } = this.props.device;
      this.setState({ device_wallbox });
    }
  }


  handleOnDeviceDeleteSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState({ formSubmitted: true });
    await this.props.deleteDeviceWallboxById(this.state.device_wallbox);
    this.setState({ formSubmitted: false });
    if(this.props.device.status === fetchStates.error) {
      this.setState({ formError: true });
      const self = this;
      setTimeout(function() { 
        if(self.state.formError === true) {
          self.setState({ formError: false });
        }
      }, 250);
    }
    if(this.props.device.status === fetchStates.success) {
      this.props.router.navigate('/devices');
    }
    this.setState({ formSubmitted: false });
  }


  render() {

    const { formSubmitted, formError } = this.state;
    const { status } = this.props.device;


    return (
      <TemplatePageWithNavigation title={ tr(trans.viewDevicesWallboxDelete.pageTitle) } buttonBack>
        <Box>
          <form className={classnames('form', { 'form-error': formError })} onSubmit={this.handleOnDeviceDeleteSubmit}>
            <div className="form-section">

              <div className="form-group">
                <input 
                  type="submit" 
                  value={ tr(trans.viewDevicesWallboxDelete.form.buttonDeleteDevice) } 
                  className="button button--primary button--small"
                  disabled={ formSubmitted && status === 'fetching' ? true : false }
                />
              </div>

            </div>
          </form>
        </Box>
      </TemplatePageWithNavigation>
    );
  }
};


export default WithAuthentication(WithWs(WithPreloader(WithRouter(connect(
  ({ device }) => ({ device }),
  { fetchDeviceWallboxById, deleteDeviceWallboxById }
)(DeviceDelete)))));