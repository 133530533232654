export const IconVisibility = (props: { size?: number, color?: string }) => (
  <span className="app-icon app-icon--form app-icon--visibility">
    <svg width={props.size ? props.size : '24px'} height={props.size ? props.size : '24px'} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="visibility-24px">
              <polygon  points="0 0 24 0 24 24 0 24"></polygon>
              <path d="M12,4.5 C7,4.5 2.73,7.61 1,12 C2.73,16.39 7,19.5 12,19.5 C17,19.5 21.27,16.39 23,12 C21.27,7.61 17,4.5 12,4.5 Z M12,17 C9.24,17 7,14.76 7,12 C7,9.24 9.24,7 12,7 C14.76,7 17,9.24 17,12 C17,14.76 14.76,17 12,17 Z M12,9 C10.34,9 9,10.34 9,12 C9,13.66 10.34,15 12,15 C13.66,15 15,13.66 15,12 C15,10.34 13.66,9 12,9 Z" id="Shape" fill={props.color ? props.color : '#000'} fillRule="nonzero"></path>
          </g>
      </g>
    </svg>
  </span>
);


export const IconVisibilityOff = (props: { size?: number, color?: string }) => (
  <span className="app-icon app-icon--form app-icon--visibility-off">
    <svg width={props.size ? props.size : '24px'} height={props.size ? props.size : '24px'} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g  stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="visibility_off-24px">
            <path d="M0,0 L24,0 L24,24 L0,24 L0,0 Z M0,0 L24,0 L24,24 L0,24 L0,0 Z M0,0 L24,0 L24,24 L0,24 L0,0 Z M0,0 L24,0 L24,24 L0,24 L0,0 Z" id="Shape"></path>
            <path d="M12,7 C14.76,7 17,9.24 17,12 C17,12.65 16.87,13.26 16.64,13.83 L19.56,16.75 C21.07,15.49 22.26,13.86 22.99,12 C21.26,7.61 16.99,4.5 11.99,4.5 C10.59,4.5 9.25,4.75 8.01,5.2 L10.17,7.36 C10.74,7.13 11.35,7 12,7 Z M2,4.27 L4.28,6.55 L4.74,7.01 C3.08,8.3 1.78,10.02 1,12 C2.73,16.39 7,19.5 12,19.5 C13.55,19.5 15.03,19.2 16.38,18.66 L16.8,19.08 L19.73,22 L21,20.73 L3.27,3 L2,4.27 Z M7.53,9.8 L9.08,11.35 C9.03,11.56 9,11.78 9,12 C9,13.66 10.34,15 12,15 C12.22,15 12.44,14.97 12.65,14.92 L14.2,16.47 C13.53,16.8 12.79,17 12,17 C9.24,17 7,14.76 7,12 C7,11.21 7.2,10.47 7.53,9.8 Z M11.84,9.02 L14.99,12.17 L15.01,12.01 C15.01,10.35 13.67,9.01 12.01,9.01 L11.84,9.02 Z" id="Shape" fill={props.color ? props.color : '#000'} fillRule="nonzero"></path>
        </g>
    </g>
    </svg>
  </span>
);


export const IconWarning = (props: {size?: number, color?: string }) => (
  <span className="app-icon app-icon--form app-icon--warning">
    <svg width={props.size ? props.size : '24px'} height={props.size ? props.size : '24px'} viewBox="0 0 486 442" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fill={props.color ? props.color : '#000'} fillRule="nonzero">
            <path d="M476.099,331.968 L305.899,37.168 C278.099,-11.532 207.799,-11.532 180.099,37.168 L9.799,331.968 C-18.001,380.668 16.599,441.168 72.699,441.168 L412.599,441.168 C468.699,441.168 503.899,380.068 476.099,331.968 Z M242.899,375.768 C228.099,375.768 215.799,363.468 215.799,348.668 C215.799,333.868 228.099,321.568 242.899,321.568 C257.699,321.568 269.999,333.868 269.399,349.368 C270.099,363.468 257.099,375.768 242.899,375.768 Z M267.599,200.568 C266.399,221.568 265.099,242.468 263.899,263.468 C263.299,270.268 263.299,276.468 263.299,283.168 C262.699,294.268 253.999,302.868 242.899,302.868 C231.799,302.868 223.199,294.868 222.499,283.768 C220.699,251.068 218.799,218.968 216.999,186.268 C216.399,177.668 215.799,168.968 215.099,160.368 C215.099,146.168 223.099,134.468 236.099,130.768 C249.099,127.668 261.999,133.868 267.599,146.168 C269.499,150.468 270.099,154.768 270.099,159.768 C269.499,173.468 268.199,187.068 267.599,200.568 Z" id="Shape"></path>
        </g>
    </g>
    </svg>
  </span>
);