import React from 'react';
import { connect } from 'react-redux';
import './with-ws.css';
import { io } from 'socket.io-client';
import classnames from 'classnames';
import { REACT_APP_WS_DOMAIN, REACT_APP_WS_PORT_DIST, REACT_APP_WS_PROTOCOL } from '../../../constants';
import { wsGotDataByDeviceSerial } from '../../../actions/device.action';
import { RootState } from '../../../reducers';


let SOCKET: any = { connected: false };
let SOCKET_ID: string;


const WithWs = (Component: any) => {


  interface withWsProps {
    device: any,
    wsGotDataByDeviceSerial: any,
  }


  interface withWsState {
    authenticated: boolean,
    wsConnected: boolean,
    wsData: any
  }


  class WithWs extends React.Component<withWsProps, withWsState> {

    state = {
      authenticated: (String(localStorage.getItem('authenticated')).toLowerCase() === 'true') || false,
      wsConnected: false,
      wsData: {},
    }


    componentDidMount = (): void => {
      console.log(`[INFO]: ==== component did mount`);
      console.log(`[INFO]: socket is connected: ${SOCKET.connected}`);
      console.log(`[INFO]: user is authenticated: ${this.state.authenticated}`);
      if(this.state.authenticated) {
        if(SOCKET && SOCKET.connected && SOCKET.id) {
          console.log(`[INFO]: already connected with ID ${SOCKET.id}`);
          this.setState({ wsConnected: true });
        } else {
          this.connectWS();
          this.serveWS();
        }
      } else {
        if(SOCKET && SOCKET.connected && SOCKET.id) {
          SOCKET.disconnect();
        }
      }
    }


    connectWS = () => {
      console.log(`[INFO]: ====== running connectWS`);
      console.log(`[INFO]: app is connecting to the following WS address: ${REACT_APP_WS_PROTOCOL}://${REACT_APP_WS_DOMAIN}:${REACT_APP_WS_PORT_DIST}`)
      SOCKET = io(`${REACT_APP_WS_PROTOCOL}://${REACT_APP_WS_DOMAIN}:${REACT_APP_WS_PORT_DIST}`, { transports: ["websocket"] });
    }


    serveWS = () => {
      console.log(`[INFO]: ====== running serveWS`);


      SOCKET.on("connect", () => {
        this.setState({ wsConnected: true });
        SOCKET_ID = SOCKET.id;
        console.log(`[INFO]: connected to ws ID: ${SOCKET_ID}`);
      });


      SOCKET.on("reconnect", () => {
        this.setState({ wsConnected: true });
        console.log(`[INFO]: re-connected to ws ID: ${SOCKET_ID}`);
      });


      // SOCKET WS DISCONNECTED
      SOCKET.on("disconnect", () => {
        console.log(`[INFO]: disconnected from socket ID: ${SOCKET_ID}`);
        this.setState({ wsConnected: false });
      });


      // SOCKET ON RECEIVING DATA
      SOCKET.on("data", (message: string) => { 
        const data = JSON.parse(message);
        console.log(`[INFO]: received following data: ${data.data}`);
        // ERROR - show message
        if(data.status === 'error') { console.error(`[ERROR]: ${data.msg}`); }
        // check that data has OK flag
        if(data.status !== 'ok') { return null; }
        // check data type
        if(data.type === 'view') {
          this.setState({ wsData: data.data });
          this.props.wsGotDataByDeviceSerial(data.data);
        }
      });

    }


    wsEmitData = (data: any) => {
      console.log(`[INFO]: emitting data: ${data}`);
      if(SOCKET && SOCKET.connected) {
        SOCKET.emit("data", JSON.stringify(data));
      }
    }


    resolveComponent () {
      if(this.state.authenticated === true) {
        return (
          <>
            <div className={classnames('ws-connection', { 'ws-connection--connected': this.state.wsConnected })}></div>
            <Component 
              { ...this.props } 
              wsConnected={ this.state.wsConnected } 
              wsData={ this.state.wsData } 
              dataCommandEmit={(data:any) => this.wsEmitData(data)}
            />
          </>
        );
      } else {
        return <Component { ...this.props } />;
      } 
    }

    render() {
      return this.resolveComponent();
    }
  }


  return connect(
    ({ device }: RootState) => ({ device }),
    { wsGotDataByDeviceSerial }
  )(WithWs);

}


export default WithWs;