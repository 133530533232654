import { DATA } from '../types/reducer.types';
import fetchStates from '../types/fetchState.types';
import InterfaceReducerData from '../intefaces/interfaceReducerData';


const DEFAULT_DATA_STATE: InterfaceReducerData = { 
  status: '',
  fields: [],
  msg: [],
  chart_energy: { categories: [''], series: [{ data: [0] }] },
  chart_cost: { categories: [''], series: [{ data: [0] }] },
  chart_time: { categories: [''], series: [{ data: [0] }] },
}


const data = (state = DEFAULT_DATA_STATE, action: any) => {
  if(action.authorizationHeader) {
    localStorage.setItem('accessToken', action.authorizationHeader);
  }
  switch(action.type) {
    case DATA.FETCH:
      return { 
        ...state, 
        status: fetchStates.fetching,
        msg: [],
        fields: [],
      };
    case DATA.FETCH_ERROR:
      return { 
        ...state, 
        status: fetchStates.error,
        msg: action.errors,
        fields: action.errors && action.errors.map((error: {path: string}) => error.path !== undefined && error.path.toString()),
      };
    case DATA.FETCH_CHART_ENERGY_SUCCESS:
      return {
        ...state,
        chart_energy: action.chart_energy,
        status: fetchStates.success,
      };
    case DATA.FETCH_CHART_COST_SUCCESS:
      return {
        ...state,
        chart_cost: action.chart_cost,
        status: fetchStates.success,
      };
    case DATA.FETCH_CHART_TIME_SUCCESS:
      return {
        ...state,
        chart_time: action.chart_time,
        status: fetchStates.success,
      };
    default:
      return state;
  }
};


export default data;