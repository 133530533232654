import React from 'react';
import { connect } from 'react-redux';
import fetchStates from '../../../types/fetchState.types';
import { fetchGroups } from '../../../actions/group.action';

import WithPreloader from '../../hoc/preloader/WithPreloader';
import WithAuthentication from '../../hoc/auth/WithAuthentication';
import WithRouter from '../../hoc/router/WithRouter';
import WithWs from '../../hoc/ws/WithWs'

import './groups.css';
import InterfaceReducerGroup from '../../../intefaces/InterfaceReducerGroup';
import { Link } from 'react-router-dom';
import { tr, trans } from '../../../translation/translations';
import Box from '../../shared/box/Box';
import { IconBoxDelete, IconBoxEdit } from '../../shared/icons/IconsTables';
import BoxTitlebar from '../../shared/box-titlebar/BoxTitlebar';
import ButtonTitlebar from '../../shared/button-titlebar/ButtonTitlebar';
import moment from 'moment';
import TemplatePageWithNavigation from '../../shared/template-page-navigation/TemplatePageWithNavigation';
import TableFooter from '../../shared/table-footer/TableFooter';
import { IconNavGroups } from '../../shared/icons/IconsNavigation';


interface GroupsProps {
  router: { location: string, navigate: (to: any) => any },
  group: InterfaceReducerGroup,
  fetchGroups: (options: { limit: number, skip: number }) => Promise<void>,
}


interface GroupsState {
  groups: Array<any>,
  groups_count: number,
  currentPage: number,
  perPage: number,
}


export class Groups extends React.Component<GroupsProps, GroupsState> {


  state = {
    groups: [],
    groups_count: 0,
    perPage: 10,
    currentPage: 0,
  }


  componentDidMount(): void {
    const { perPage, currentPage } = this.state;
    this.reloadPage({ perPage, currentPage });
  }


  updatePagination = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    this.setState({ perPage: parseInt(e.target.value), currentPage: 0  });
    this.reloadPage({ perPage: parseInt(e.target.value), currentPage: 0  });
    this.props.router.navigate(`/groups?page=0&perPage=${e.target.value}`);
  }


  prevPage = (prevPage: number) => {
    this.setState({ currentPage: prevPage });
    this.reloadPage({ currentPage: prevPage, perPage: this.state.perPage });
    this.props.router.navigate(`/groups?page=${prevPage}&perPage=${this.state.perPage}`);
  }


  nextPage = (nextPage: number) => {
    this.setState({ currentPage: nextPage });
    this.reloadPage({ currentPage: nextPage, perPage: this.state.perPage });
    this.props.router.navigate(`/groups?page=${nextPage}&perPage=${this.state.perPage}`);
  }


  reloadPage = async (options: { perPage: number, currentPage: number }) => {
    await this.props.fetchGroups({ limit: options.perPage, skip: options.perPage * options.currentPage });
    if(this.props.group.status === fetchStates.success) {
      const { groups, groups_count } = this.props.group;
      this.setState({ groups, groups_count });
    }
  }
  

  render() {

    const { groups, groups_count, currentPage, perPage } = this.state;

    return (
      <TemplatePageWithNavigation title={ tr(trans.viewGroups.pageTitle) }>

        <Box>
          <BoxTitlebar title={ tr(trans.viewGroups.boxTitleManageGroups) } buttons={ [ <ButtonTitlebar key="0" title={ tr(trans.viewGroups.boxButtonAddGroup) } to="/groups/add" primary /> ] } />
          <div className="table">
            <div className="row header">
              <div className="cell cell-buttons"></div>
              <div className="cell">{ tr(trans.viewGroups.tableHeader.title) }</div>
              <div className="cell">{ tr(trans.viewGroups.tableHeader.color) }</div>
              <div className="cell">{ tr(trans.viewGroups.tableHeader.type) }</div>
              <div className="cell">{ tr(trans.viewGroups.tableHeader.registered) }</div>
            </div>
            
            { groups && groups.map((group: any) => {
              return (
                <div className="row" key={ group.group_id }>
                  <div className="cell">
                    <Link className="table-button" to={`/groups/edit/${ group.group_id }`}>
                      <IconBoxEdit color="#fff" size={22} />
                    </Link>
                    {
                      group.group_master === false && (
                        <Link className="table-button" to={`/groups/delete/${ group.group_id }`}>
                          <IconBoxDelete color="#fff" size={24} />
                        </Link>
                      )
                    }
                  </div>
                  <div className="cell" data-title={ tr(trans.viewGroups.tableHeader.title) }>
                    <span className="table-identity-icon">
                      <IconNavGroups color="#666" size={16} />
                    </span>
                    { group.group_title }
                  </div>
                  <div className="cell" data-title={ tr(trans.viewGroups.tableHeader.color) }><span className="table-label table-label--circle" style={{ background: group.group_color }}></span></div>
                  <div className="cell" data-title={ tr(trans.viewGroups.tableHeader.type) }>
                    { group.group_master === true ? 
                      (<span className="table-label table-label--text table-label--bg-default">{ tr(trans.viewGroups.tableLabel.primary) }</span>)
                        : 
                      (<span className="table-label table-label--text table-label--bg-custom">{ tr(trans.viewGroups.tableLabel.custom) }</span>)
                    }
                  </div>
                  <div className="cell" data-title={ tr(trans.viewGroups.tableHeader.registered) }>{ moment(group.group_registered).format('YYYY-MM-DD') }</div>

                </div>
              )
            })}

          </div>
          <TableFooter 
            currentPage={ currentPage }
            perPage={ perPage }
            itemsCount={ groups_count }
            prevPage={() => this.prevPage(currentPage - 1)}
            nextPage={() => this.nextPage(currentPage + 1)}
            updatePagination={this.updatePagination}
          />
        </Box>

      </TemplatePageWithNavigation>

    );
  }
};


export default WithAuthentication(WithWs(WithPreloader(WithRouter(connect(
  ({ group }) => ({ group }),
  { fetchGroups }
)(Groups)))));