import React from 'react';
import './box.css';


interface BoxProps {
  children?: JSX.Element | JSX.Element[]
}


const BoxLog: React.FC<BoxProps> = ({ children }) => {
  return (
    <div className="box">
      { children }
    </div>
  );
};


export default BoxLog;