import { DEVICE, PRELOADER } from '../types/reducer.types';
import { errorNotification, successNotification } from '../functions/notifications';


const fetchDevice = (options: { endpoint: string, options: any, SUCCESS_TYPE: string }) => (dispatch:any) => {

  dispatch({ type: DEVICE.FETCH });
  let authorizationHeader: any = [];
  dispatch({ type: PRELOADER.SHOW_PRELOADER });

  return fetch(`/api/device/${options.endpoint}`, options.options)
          .then(response => {
              authorizationHeader = response.headers.get('Authorization');
              return response.json();
            }
          )
          .then(json => {
            dispatch({ type: PRELOADER.HIDE_PRELOADER });
            if (json.errors !== undefined) {
              dispatch({ type: DEVICE.FETCH_ERROR, ...json, authorizationHeader });
              json && json.errors && json.errors.map((item: {msg: string}) => {
                if(item.msg) {
                    errorNotification(item.msg);
                  }
                  return null;
              });
            } else {            
              dispatch({ type: options.SUCCESS_TYPE, ...json }, authorizationHeader);
              json && json.success && json.success.map((item: {msg: string}) => {
                if(item.msg) {
                  successNotification(item.msg);
                }
                return null
              });
            }
          })
          .catch(error => dispatch({
            type: DEVICE.FETCH_ERROR, errors: [{ msg: error }], authorizationHeader
          }));
  }


  export const fetchDeviceWallboxCount = () => fetchDevice({
    endpoint: `wallbox/count`,
    options: { 
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      },
      credentials: 'include' 
    },
    SUCCESS_TYPE: DEVICE.FETCH_DEVICE_WALLBOX_COUNT_SUCCESS
  });


  export const fetchDeviceWallbox = (options: { limit: number, skip:number }) => fetchDevice({
    endpoint: `wallbox/?limit=${options.limit}&skip=${options.skip}`,
    options: { 
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      },
      credentials: 'include' 
    },
    SUCCESS_TYPE: DEVICE.FETCH_DEVICE_WALLBOX_SUCCESS
  });


  export const fetchDeviceWallboxOverviewCount = (group_id: string) => fetchDevice({
    endpoint: `wallbox/overview/count/?group_id=${group_id}`,
    options: { 
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      },
      credentials: 'include' 
    },
    SUCCESS_TYPE: DEVICE.FETCH_DEVICE_WALLBOX_OVERVIEW_COUNT_SUCCESS
  });


  export const fetchDeviceWallboxOverview = (options: { group_id: string, limit: number, skip:number }) => fetchDevice({
    endpoint: `wallbox/overview/?group_id=${options.group_id}&limit=${options.limit}&skip=${options.skip}`,
    options: { 
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      },
      credentials: 'include' 
    },
    SUCCESS_TYPE: DEVICE.FETCH_DEVICE_WALLBOX_OVERVIEW_SUCCESS
  });


  export const fetchDeviceWallboxById = (device_id: string) => fetchDevice({
    endpoint: `wallbox/${device_id}`,
    options: { 
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      },
      credentials: 'include' 
    },
    SUCCESS_TYPE: DEVICE.FETCH_DEVICE_WALLBOX_BY_ID_SUCCESS
  });


  export const updateDeviceWallboxById = (device_wallbox: any) => fetchDevice({
    endpoint: `wallbox/${device_wallbox.device_id}`,
    options: {
      method: 'PUT',
      body: JSON.stringify({ device_wallbox }),
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      },
    },
    SUCCESS_TYPE: DEVICE.FETCH_UPDATE_DEVICE_WALLBOX_BY_ID_SUCCESS
  });


  export const addDeviceWallbox = (device_wallbox: any) => fetchDevice({
    endpoint: `wallbox`,
    options: {
      method: 'POST',
      body: JSON.stringify({ device_wallbox }),
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      },
    },
    SUCCESS_TYPE: DEVICE.FETCH_INSERT_DEVICE_WALLBOX_SUCCESS
  });
  

  export const deleteDeviceWallboxById = (device_wallbox: any) => fetchDevice({
    endpoint: `wallbox/${device_wallbox.device_id}`,
    options: {
      method: 'DELETE',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      },
    },
    SUCCESS_TYPE: DEVICE.FETCH_DELETE_DEVICE_WALLBOX_BY_ID_SUCCESS
  });


  export const fetchDeviceWallboxAccountChargingEvents = (options: { limit: number, skip:number }) => fetchDevice({
    endpoint: `wallbox/account-charging-events/?limit=${options.limit}&skip=${options.skip}`,
    options: { 
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      },
      credentials: 'include' 
    },
    SUCCESS_TYPE: DEVICE.FETCH_DEVICE_WALLBOX_ACCOUNT_CHARGING_EVENTS_SUCCESS
  });


  export const fetchDeviceWallboxChargingEventsByDeviceSerial = (options: { device_id: string, limit: number, skip:number }) => fetchDevice({
    endpoint: `wallbox/device-charging-events/?device_id=${options.device_id}&limit=${options.limit}&skip=${options.skip}`,
    options: { 
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      },
      credentials: 'include' 
    },
    SUCCESS_TYPE: DEVICE.FETCH_DEVICE_WALLBOX_CHARGING_EVENTS_SUCCESS
  });


  export const wsGotDataByDeviceSerial = (device_data:any) => async (dispatch: any) => {
    await dispatch({ type: DEVICE.WS_DATA_BY_DEVICE_SERIAL, device_data }); 
  };
  


