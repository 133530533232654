import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import { RootState } from '../../../reducers';
import { connect } from 'react-redux';
import { confirmRegistrationPin } from '../../../actions/auth.action';
import TemplatePageFullwidth from '../../shared/template-page-fullwidth/TemplatePageFullwidth';
import classnames from 'classnames';
import Language from '../../shared/language/Language';
import { tr, trans } from '../../../translation/translations';
import fetchStates from '../../../types/fetchState.types';
import InterfaceReducerAuth from '../../../intefaces/InterfaceReducerAuth';
import { IconBack } from '../../shared/icons/IconsNavigation';
import WithRouter from '../../hoc/router/WithRouter';


interface AuthRegisterPinProps {
  router: { location: string, navigate: (to: any) => any },
  auth: InterfaceReducerAuth,
  confirmRegistrationPin: (register_pin: string) => Promise<any>, 
};


interface AuthRegisterPinState {
  formSubmitted: boolean,
  number1: string,
  number2: string,
  number3: string,
  number4: string,
  number5: string,
  number6: string
};


export class AuthRegisterPin extends React.Component<AuthRegisterPinProps, AuthRegisterPinState> {


  public inputRef1: React.RefObject<HTMLInputElement>;
  public inputRef2: React.RefObject<HTMLInputElement>;
  public inputRef3: React.RefObject<HTMLInputElement>;
  public inputRef4: React.RefObject<HTMLInputElement>;
  public inputRef5: React.RefObject<HTMLInputElement>;
  public inputRef6: React.RefObject<HTMLInputElement>;
  constructor(props: any) {
    super(props);
    this.inputRef1 = React.createRef();
    this.inputRef2 = React.createRef();
    this.inputRef3 = React.createRef();
    this.inputRef4 = React.createRef();
    this.inputRef5 = React.createRef();
    this.inputRef6 = React.createRef();
  }


  state = {
    formSubmitted: false,
    number1: '',
    number2: '',
    number3: '',
    number4: '',
    number5: '',
    number6: '',
  };


  updateNumber1 = (e: any) => {
    if(!isNaN(e.target.value)) {
      this.setState({ number1: e.target.value }, () => this.submitPIN());
      if(e.target.value.length > 5) {
        this.setState({
          number1: e.target.value[0],
          number2: e.target.value[1],
          number3: e.target.value[2],
          number4: e.target.value[3],
          number5: e.target.value[4],
          number6: e.target.value[5],
        })
      }
      if (this.inputRef2.current && e.target.value.length > 0) {
        this.inputRef2.current.focus();
        this.inputRef2.current.select();
      }
    }
  }

  
  updateNumber2 = (e: any) => {
    if(!isNaN(e.target.value)) {
      this.setState({ number2: e.target.value }, () => this.submitPIN())
      if (this.inputRef3.current && e.target.value.length > 0) {
        this.inputRef3.current.focus();
        this.inputRef3.current.select();
      }
    }
  }


  updateNumber3 = (e: any) => {
    if(!isNaN(e.target.value)) {
      this.setState({ number3: e.target.value }, () => this.submitPIN())
      if (this.inputRef4.current && e.target.value.length > 0) {
        this.inputRef4.current.focus();
        this.inputRef4.current.select();
      }
    }
  }


  updateNumber4 = (e: any) => {
    if(!isNaN(e.target.value)) {
      this.setState({ number4: e.target.value }, () => this.submitPIN())
      if (this.inputRef5.current && e.target.value.length > 0) {
        this.inputRef5.current.focus();
        this.inputRef5.current.select();
      }
    }
  }


  updateNumber5 = (e: any) => {
    if(!isNaN(e.target.value)) {
      this.setState({ number5: e.target.value }, () => this.submitPIN())
      if (this.inputRef6.current && e.target.value.length > 0) {
        this.inputRef6.current.focus();
        this.inputRef6.current.select();
      }
    }
  }


  updateNumber6 = (e: any) => {
    if(!isNaN(e.target.value)) {
      this.setState({ number6: e.target.value }, () => this.submitPIN());
    }
  }

  clearNumber2 = (e: any) => {
    if(e.keyCode === 8) {
      if (this.inputRef1.current && e.target.value.length === 0) {
        this.inputRef1.current.focus();
        this.inputRef1.current.select();
      }
    }
  }

  clearNumber3 = (e: any) => {
    if(e.keyCode === 8) {
      if (this.inputRef2.current && e.target.value.length === 0) {
        this.inputRef2.current.focus();
        this.inputRef2.current.select();
      }
    }
  }

  clearNumber4 = (e: any) => {
    if(e.keyCode === 8) {
      if (this.inputRef3.current && e.target.value.length === 0) {
        this.inputRef3.current.focus();
        this.inputRef3.current.select();
      }
    }
  }

  clearNumber5 = (e: any) => {
    if(e.keyCode === 8) {
      if (this.inputRef4.current && e.target.value.length === 0) {
        this.inputRef4.current.focus();
        this.inputRef4.current.select();
      }
    }
  }

  clearNumber6 = (e: any) => {
    if(e.keyCode === 8) {
      if (this.inputRef5.current && e.target.value.length === 0) {
        this.inputRef5.current.focus();
        this.inputRef5.current.select();
      }
    }
  }


  submitPIN = async () => {
    if (
      this.state.number1 !== '' &&
      this.state.number2 !== '' &&
      this.state.number3 !== '' &&
      this.state.number4 !== '' &&
      this.state.number5 !== '' &&
      this.state.number6 !== ''
    ) {
      const { number1, number2, number3, number4, number5, number6 } = this.state;
      const register_pin = number1.toString() + number2.toString() + number3.toString() + number4.toString() + number5.toString() + number6.toString();
      await this.props.confirmRegistrationPin(register_pin);
      if(this.props.auth.status === fetchStates.success) {
        this.setState({ 
          number1: '',
          number2: '',
          number3: '',
          number4: '',
          number5: '',
          number6: ''
        });
        this.props.router.navigate(`/login`);
      }
    }
  }


  render() {

    const { authenticated, fields } = this.props.auth;
    const { number1, number2, number3, number4, number5, number6 } = this.state;

    return authenticated ? <Navigate to="/" /> : (
      <TemplatePageFullwidth>
        <Language />
        <div className="page page--auth page__login">
          <div className="auth-back-button">
            <Link to="/">
              <IconBack color="#000" size={40} />
            </Link>
          </div>

          <div className="form form--auth">
            <div>
              <p className="paragraph paragraph--auth center">{ tr(trans.viewRegisterPin.paragraphInstructions) }</p>
            </div>
            <div className=" auth-pins-wrapper">
              <div className="form-group form-group--auth auth-pins">
                <input
                  id="number1"
                  type="test"
                  value={ number1 }
                  ref={ this.inputRef1 } 
                  onChange={e => this.updateNumber1(e)}
                  className={classnames('auth-pin', { 'auth-pin--error': fields && fields.includes('reset_pin') })}
                />
                <input
                  id="number2"
                  type="test"
                  value={ number2 }
                  ref={ this.inputRef2 } 
                  onChange={e => this.updateNumber2(e)}
                  onKeyDown={e => this.clearNumber2(e)}
                  className={classnames('auth-pin', { 'auth-pin--error': fields && fields.includes('reset_pin') })}
                />
                <input
                  id="number3"
                  type="test"
                  value={ number3 }
                  ref={ this.inputRef3 } 
                  onChange={e => this.updateNumber3(e)}
                  onKeyDown={e => this.clearNumber3(e)}
                  className={classnames('auth-pin', { 'auth-pin--error': fields && fields.includes('reset_pin') })}
                />
                <input
                  id="number4"
                  type="test"
                  value={ number4 }
                  ref={ this.inputRef4 } 
                  onChange={e => this.updateNumber4(e)}
                  onKeyDown={e => this.clearNumber4(e)}
                  className={classnames('auth-pin', { 'auth-pin--error': fields && fields.includes('reset_pin') })}
                />
                <input
                  id="number5"
                  type="test"
                  value={ number5 }
                  ref={ this.inputRef5 } 
                  onChange={e => this.updateNumber5(e)}
                  onKeyDown={e => this.clearNumber5(e)}
                  className={classnames('auth-pin', { 'auth-pin--error': fields && fields.includes('reset_pin') })}
                />
                <input
                  id="number6"
                  type="test"
                  value={ number6 }
                  ref={ this.inputRef6 } 
                  onChange={e => this.updateNumber6(e)}
                  onKeyDown={e => this.clearNumber6(e)}
                  className={classnames('auth-pin', { 'auth-pin--error': fields && fields.includes('reset_pin') })}
                />
              </div>
            </div>
          </div>
        </div>
      </TemplatePageFullwidth>
    );
  }
};


export default WithRouter(connect(
  ({ auth }: RootState) => ({ auth }),
  { confirmRegistrationPin }
)(AuthRegisterPin));