import Chart from "react-apexcharts";
import secondsToH from "../../../functions/secondsToH";


interface DashboardChartProps {
  categories: Array<string>,
  series: Array<any>,
  ySuffix?: string,
}


const DashboardChart: React.FC<DashboardChartProps> = ({ categories, series, ySuffix }) => {

  const options = {
    chart: {
      toolbar: {
        show: false,
        width: '10%'
      }
    },
    xaxis: {
      categories,
      tooltip: {
        enabled: false
      }
    },
    colors: ["#17B566"],
    dataLabels: {
      enabled: false
    },
    tooltip: {
      enabled: false
    },
    legend: {
      show: false
    },
    plotOptions: {
      bar: {
        columnWidth: '12px',
        borderRadius: 4,
      }
    },
    yaxis: {
      labels: {
        formatter: function (value:any) {
          return ySuffix === 'h' ? `${secondsToH(value)}` : `${value}`;
        }
      },
      tooltip: {
        enabled: false
      }
    },
  }


  return (
    <div className="app">
      <div className="row">
        <div className="mixed-chart">
          <Chart
            options={options}
            series={series}
            type="bar"
          />
        </div>
      </div>
    </div>
  );
}


export default DashboardChart;