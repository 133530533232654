import InterfaceReducerPreloader from '../intefaces/InterfaceReducerPreloader';
import { PRELOADER } from '../types/reducer.types';



const DEFAULT_PRELOADER_STATE: InterfaceReducerPreloader = { 
  preloaderVisible: false,
}


const auth = (state = DEFAULT_PRELOADER_STATE, action: any) => {
  switch(action.type) {
    case PRELOADER.SHOW_PRELOADER:
      return { 
        ...state,
        preloaderVisible: true
      };
    case PRELOADER.HIDE_PRELOADER:
      return { 
        ...state,
        preloaderVisible: false
      };
    default:
      return state;
  }
};


export default auth;