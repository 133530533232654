import { combineReducers } from 'redux';
import preloader from './preloader.reducer';
import auth from './auth.reducer';
import device from './device.reducer';
import data from './data.reducer';
import group from './group.reducer';
import person from './person.reducer';
import event from './event.reducer';


export const rootReducer = combineReducers({
  preloader,
  auth,
  device,
  data,
  group,
  person,
  event
});


export type RootState = ReturnType<typeof rootReducer>;