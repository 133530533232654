const PRELOADER = {
  SHOW_PRELOADER: 'PRELOADER_SHOW_PRELOADER',
  HIDE_PRELOADER: 'PRELOADER_HIDE_PRELOADRE',
};


const AUTH = {
  FETCH: 'AUTH_FETCH',
  FETCH_ERROR: 'AUTH_FETCH_ERROR',
  FETCH_LOGIN_SUCCESS: 'AUTH_FETCH_LOGIN_SUCCESS',
  FETCH_REGISTER_SUCCESS: 'AUTH_FETCH_REGISTER_SUCCESS',
  FETCH_REGISTER_PIN_RESEND_SUCCESS: 'AUTH_FETCH_REGISTER_PIN_RESEND_SUCCESS',
  FETCH_REGISTER_PIN_SUCCESS: 'AUTH_FETCH_REGISTER_PIN_SUCCESS',
  FETCH_AUTHENTICATED_SUCCESS: 'AUTH_FETCH_AUTHENTICATED_SUCCESS',
  FETCH_LOGOUT_SUCCESS: 'AUTH_FETCH_LOGOUT_SUCCESS',
  FETCH_LOGOUT_EVERYWHERE_SUCCESS: 'AUTH_FETCH_LOGOUT_EVERYWHERE_SUCCESS',
  FETCH_PASSWORD_FORGOT_SUCCESS: 'AUTH_FETCH_PASSWORD_FORGOT_SUCCESS',
  FETCH_PASSWORD_CHECK_SUCCESS: 'AUTH_FETCH_PASSWORD_CHECK_SUCCESS',
  FETCH_PASSWORD_RESET_SUCCESS: 'AUTH_FETCH_PASSWORD_RESET_SUCCESS',
  FETCH_PASSWORD_CHANGE_SUCCESS: 'AUTH_FETCH_PASSWORD_CHANGE_SUCCESS',
  FETCH_LOGIN_LOGS_SUCCESS: 'AUTH_FETCH_LOGIN_LOGS_SUCCESS',
  FETCH_ACCOUNT_SETTINGS_UPDATE_SUCCESS: 'AUTH_FETCH_ENERGY_COST_UPDATE_SUCCESS',
  FETCH_ACCOUNT_SETTINGS_SUCCESS: 'AUTH_FETCH_ENERGY_COST_SUCCESS'
};


const GROUP = {
  FETCH: 'GROUP_FETCH',
  FETCH_ERROR: 'GROUP_FETCH_ERROR',
  FETCH_GROUPS_COUNT_SUCCESS: 'GROUP_FETCH_GROUP_COUNT_SUCCESS',
  FETCH_GROUPS_SUCCESS: 'GROUP_FETCH_GROUP_SUCCESS',
  FETCH_GROUPS_ACCOUNT_SUCCESS: 'GROUP_FETCH_GROUP_ACCOUNT_SUCCESS',
  FETCH_INSERT_GROUP_SUCCESS: 'GROUP_FETCH_INSERT_GROUP_SUCCESS',
  FETCH_GROUP_BY_ID_SUCCESS: 'GROUP_FETCH_GROUP_BY_ID_SUCCESS',
  FETCH_UPDATE_GROUP_BY_ID_SUCCESS: 'GROUP_FETCH_UPDATE_GROUP_BY_ID_SUCCESS',
  FETCH_DELETE_GROUP_BY_ID_SUCCESS: 'GROUP_FETCH_DELETE_GROUP_BY_ID_SUCCESS',
  FETCH_GROUPS_OVERVIEW_SUCCESS: 'GROUP_FETCH_GROUPS_OVERVIEW_SUCCESS'
}


const PERSON = {
  FETCH: 'PERSON_FETCH',
  FETCH_ERROR: 'PERSON_FETCH_ERROR',
  FETCH_PERSON_COUNT_SUCCESS: 'PERSON_FETCH_PERSON_COUNT_SUCCESS',
  FETCH_PERSON_SUCCESS: 'PERSON_FETCH_PERSON_SUCCESS',
  FETCH_INSERT_PERSON_SUCCESS: 'PERSON_FETCH_INSERT_PERSON_SUCCESS',
  FETCH_PERSON_BY_ID_SUCCESS: 'PERSON_FETCH_PERSON_BY_ID_SUCCESS',
  FETCH_UPDATE_PERSON_BY_ID_SUCCESS: 'PERSON_FETCH_UPDATE_PERSON_BY_ID_SUCCESS',
  FETCH_DELETE_PERSON_BY_ID_SUCCESS: 'PERSON_FETCH_DELETE_PERSON_BY_ID_SUCCESS'
}


const DEVICE = {
  FETCH: 'DEVICE_FETCH',
  FETCH_ERROR: 'DEVICE_FETCH_ERROR',
  FETCH_DEVICE_WALLBOX_COUNT_SUCCESS: 'DEVICE_FETCH_DEVICE_WALLBOX_COUNT_SUCCESS',
  FETCH_DEVICE_WALLBOX_SUCCESS: 'DEVICE_FETCH_DEVICE_WALLBOX_SUCCESS',
  FETCH_DEVICE_WALLBOX_OVERVIEW_COUNT_SUCCESS: 'DEVICE_FETCH_DEVICE_WALLBOX_OVERVIEW_COUNT_SUCCESS',
  FETCH_DEVICE_WALLBOX_OVERVIEW_SUCCESS: 'DEVICE_FETCH_DEVICE_WALLBOX_OVERVIEW_SUCCESS',
  FETCH_DEVICE_WALLBOX_BY_ID_SUCCESS: 'DEVICE_FETCH_DEVICE_WALLBOX_BY_ID_SUCCESS',
  FETCH_UPDATE_DEVICE_WALLBOX_BY_ID_SUCCESS: 'DEVICE_FETCH_UPDATE_DEVICE_WALLBOX_BY_ID_SUCCESS',
  FETCH_DELETE_DEVICE_WALLBOX_BY_ID_SUCCESS: 'DEVICE_FETCH_DELETE_DEVICE_WALLBOX_BY_ID_SUCCESS',
  FETCH_INSERT_DEVICE_WALLBOX_SUCCESS: 'DEVICE_FETCH_INSERT_DEVICE_WALLBOX_SUCCESS',
  FETCH_DEVICE_WALLBOX_ACCOUNT_CHARGING_EVENTS_SUCCESS: 'FETCH_DEVICE_WALLBOX_ACCOUNT_CHARGING_EVENTS_SUCCESS',
  FETCH_DEVICE_WALLBOX_CHARGING_EVENTS_SUCCESS: 'DEVICE_FETCH_DEVICE_WALLBOX_CHARGING_EVENTS_SUCCESS',
  FETCH_CHART_ENERGY_SUCCESS: 'DEVICE_FETCH_CHART_ENERGY_SUCCESS',
  FETCH_CHART_COST_SUCCESS: 'DEVICE_FETCH_CHART_COST_SUCCESS',
  FETCH_CHART_TIME_SUCCESS: 'DEVICE_FETCH_CHART_TIME_SUCCESS',
  WS_DATA_BY_DEVICE_SERIAL: 'DEVICE_WS_DATA_BY_DEVICE_SERIAL'
};


const DATA = {
  FETCH: 'DATA_FETCH',
  FETCH_ERROR: 'DATA_FETCH_ERROR',
  FETCH_CHART_ENERGY_SUCCESS: 'DATA_FETCH_CHART_ENERGY_SUCCESS',
  FETCH_CHART_COST_SUCCESS: 'DATA_FETCH_CHART_COST_SUCCESS',
  FETCH_CHART_TIME_SUCCESS: 'DATA_FETCH_CHART_TIME_SUCCESS'
};


const EVENT = {
  FETCH: 'EVENT_FETCH',
  FETCH_ERROR: 'EVENT_FETCH_ERROR',
  FETCH_EVENTS_COUNT_SUCCESS: 'EVENT_FETCH_EVENT_COUNT_SUCCESS',
  FETCH_EVENTS_SUCCESS: 'EVENT_FETCH_EVENT_SUCCESS',
  FETCH_EVENT_BY_ID_SUCCESS: 'EVENT_FETCH_EVENT_BY_ID_SUCCESS'
};





export { PRELOADER, AUTH, GROUP, PERSON, DEVICE, EVENT, DATA };