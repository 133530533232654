import React from 'react';
import './language.css';
import getCookie from '../../../functions/cookieGet';
import setCookie from '../../../functions/cookieSet';
import { COOKIES_LANGUAGE_DEFAULT, COOKIES_LANGUAGE_EXPIRE_DAYS } from '../../../constants';
import languageTranslations from '../../../functions/languatgeTranslations';



class Language extends React.Component {

  state = {
    language: 'en',
    languageTranslations
  }


  componentDidMount(): void {
    if(!getCookie('acenergyLanguage')) {
      console.log('[INFO]: no language set, setting it to en');
      setCookie('acenergyLanguage', COOKIES_LANGUAGE_DEFAULT, COOKIES_LANGUAGE_EXPIRE_DAYS);
    } else {
      this.setState({ language: getCookie('acenergyLanguage') });
    }
  }


  setLanguage = (language: string) => {
    console.log('[INFO]: setting language to', language);
    setCookie('acenergyLanguage', language, COOKIES_LANGUAGE_EXPIRE_DAYS);
    this.setState({ language });
    window.location.reload();
  }


  render() {

    const { languageTranslations, language } = this.state;

    return (
      <div className="language-selection language-selection--auth">
        <select 
          name="language" 
          id="language"
          onChange={ (e:any) => this.setLanguage(e.target.value) }
          value={ language }
        >
          {
            languageTranslations.map((language, index) => (
              <option key={ index } value={ language.shortcut }>{ language.shortcut.toUpperCase() }</option>
            ))
          }
        </select>
      </div>
    );
  }

};


export default Language;