import { EVENT } from '../types/reducer.types';
import fetchStates from '../types/fetchState.types';
import InterfaceReducerEvent from '../intefaces/interfaceReducerEvent';


const DEFAULT_EVENT_STATE: InterfaceReducerEvent = { 
  status: '',
  fields: [],
  msg: [],
  events_count: 0,
  events: [],
  event: []
}


const event = (state = DEFAULT_EVENT_STATE, action: any) => {
  if(action.authorizationHeader) {
    localStorage.setItem('accessToken', action.authorizationHeader);
  }
  switch(action.type) {
    case EVENT.FETCH:
      return { 
        ...state, 
        status: fetchStates.fetching,
        msg: [],
        fields: [],
      };
    case EVENT.FETCH_ERROR:
      return { 
        ...state, 
        status: fetchStates.error,
        msg: action.errors,
        fields: action.errors && action.errors.map((error: {path: string}) => error.path !== undefined && error.path.toString()),
      };
    case EVENT.FETCH_EVENTS_COUNT_SUCCESS:
      return {
        ...state,
        events_count: action.events_count,
        status: fetchStates.success,
      };
    case EVENT.FETCH_EVENTS_SUCCESS:
      return {
        ...state,
        events: action.events,
        events_count: action.events_count,
        status: fetchStates.success,
      };
    case EVENT.FETCH_EVENT_BY_ID_SUCCESS:
      return {
        ...state,
        event: action.event,
        status: fetchStates.success,
      };
    default:
      return state;
  }
};


export default event;