import React from 'react';
import './template-page-fullwidth.css';


interface TemplatePageFullwidthProps {
  children: JSX.Element | JSX.Element[]
}


const TemplatePageFullwidth: React.FC<TemplatePageFullwidthProps> = ({ children }) => {
  return (
    <div className="template template__page template__page--fullwidth">
      <div className="main-content">
        <div className="main-content__wrapper">
          { children }
        </div>
      </div>
    </div>
  );
};


export default TemplatePageFullwidth;