import { Store } from 'react-notifications-component';


export const errorNotification = (msg: string) => {
  Store.addNotification({
    message: msg,
    type: "danger",
    insert: "top",
    container: "bottom-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 6000,
      onScreen: true
    }
  })
}


export const successNotification = (msg: string) => {
  Store.addNotification({
    message: msg,
    type: "success",
    insert: "top",
    container: "bottom-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 6000,
      onScreen: true
    }
  })
}