import { DEVICE } from '../types/reducer.types';
import fetchStates from '../types/fetchState.types';
import InterfaceReducerDevice from '../intefaces/InterfaceReducerDevice';


const DEFAULT_DEVICE_STATE: InterfaceReducerDevice = { 
  status: '',
  fields: [],
  msg: [],
  devices_wallbox_count: 0,
  devices_wallbox: [],
  device_wallbox: [],
  device_wallbox_charging_events_count: 0,
  device_wallbox_charging_events: [],
  devices_wallbox_overview_count: 0,
  device_wallbox_account_charging_events_count: 0,
  device_wallbox_account_charging_events: [],
  devices_wallbox_overview: [],
}


const device = (state = DEFAULT_DEVICE_STATE, action: any) => {
  if(action.authorizationHeader) {
    localStorage.setItem('accessToken', action.authorizationHeader);
  }
  switch(action.type) {
    case DEVICE.FETCH:
      return { 
        ...state, 
        status: fetchStates.fetching,
        msg: [],
        fields: [],
      };
    case DEVICE.FETCH_ERROR:
      return { 
        ...state, 
        status: fetchStates.error,
        msg: action.errors,
        fields: action.errors && action.errors.map((error: {path: string}) => error.path !== undefined && error.path.toString()),
      };
    case DEVICE.FETCH_DEVICE_WALLBOX_COUNT_SUCCESS:
      return {
        ...state,
        devices_wallbox_count: action.devices_wallbox_count,
        status: fetchStates.success,
      };
    case DEVICE.FETCH_DEVICE_WALLBOX_SUCCESS:
      return {
        ...state,
        devices_wallbox: action.devices_wallbox,
        devices_wallbox_count: action.devices_wallbox_count,
        status: fetchStates.success,
      };


    case DEVICE.FETCH_DEVICE_WALLBOX_OVERVIEW_COUNT_SUCCESS:
      return {
        ...state,
        devices_wallbox_overview_count: action.devices_wallbox_overview_count,
        status: fetchStates.success,
      };
    case DEVICE.FETCH_DEVICE_WALLBOX_OVERVIEW_SUCCESS:
      return {
        ...state,
        devices_wallbox_overview: action.devices_wallbox_overview,
        devices_wallbox_overview_count: action.devices_wallbox_overview_count,
        status: fetchStates.success,
      };



    case DEVICE.FETCH_DEVICE_WALLBOX_BY_ID_SUCCESS:
      return {
        ...state,
        device_wallbox: action.device_wallbox,
        status: fetchStates.success,
      };
    case DEVICE.FETCH_UPDATE_DEVICE_WALLBOX_BY_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
        fields: action.errors && action.errors.map((error: {path: string}) => error.path !== undefined && error.path.toString()),
      };
    case DEVICE.FETCH_DELETE_DEVICE_WALLBOX_BY_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
        fields: action.errors && action.errors.map((error: {path: string}) => error.path !== undefined && error.path.toString()),
      };
    case DEVICE.FETCH_INSERT_DEVICE_WALLBOX_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
        fields: action.errors && action.errors.map((error: {path: string}) => error.path !== undefined && error.path.toString()),
      };
    case DEVICE.FETCH_DEVICE_WALLBOX_ACCOUNT_CHARGING_EVENTS_SUCCESS:
      return {
        ...state,
        device_wallbox_account_charging_events_count: action.device_wallbox_account_charging_events_count,
        device_wallbox_account_charging_events: action.device_wallbox_account_charging_events,
        status: fetchStates.success,
      };
    case DEVICE.FETCH_DEVICE_WALLBOX_CHARGING_EVENTS_SUCCESS:
      return {
        ...state,
        device_wallbox_charging_events_count: action.device_wallbox_charging_events_count,
        device_wallbox_charging_events: action.device_wallbox_charging_events,
        status: fetchStates.success,
      };


    case DEVICE.WS_DATA_BY_DEVICE_SERIAL:
      const device_wallbox = state.device_wallbox.device_serial === action.device_data.device_serial ? {...state.device_wallbox, device_data: action.device_data.data} : state.device_wallbox;
      const devices_wallbox = state.devices_wallbox.map((device: any) => {
        if(action.device_data.device_serial === device.device_serial) {
          return {...device, device_data: action.device_data}
        } else { return device } 
      });

      return {
        ...state,
        device_wallbox,
        devices_wallbox
      }
    default:
      return state;
  }
};


export default device;