export const IconLogoDarkDesktop = (props: { height?: number, width?: number, color?: string }) => (
  <span className="app-icon app-icon--logo app-icon-logo-dark-desktop">
    <svg width={props.width ? props.width : '24px'} height={props.height ? props.height : '24px'} viewBox="0 0 224 64" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
                <polygon id="Path" fill="#1D1E1D" points="53.3466 16.8246 53.3466 27.7669 66.2199 27.7669 66.2199 32.7553 53.3466 32.7553 53.3466 44.1803 67.829 44.1803 67.829 49.1687 47.2318 49.1687 47.2318 11.8362 67.829 11.8362 67.829 16.8246"></polygon>
                <polygon id="Path" fill="#17B566" points="98.0498 27.1768 98.0498 32.3262 76.0043 32.3262 76.0043 27.1768"></polygon>
                <path d="M119.02,49.5441 C116.517,49.5441 114.264,49.115 112.262,48.2568 C110.259,47.3628 108.686,46.1113 107.542,44.5021 C106.397,42.893 105.825,41.0156 105.825,38.8701 L112.369,38.8701 C112.512,40.4792 113.138,41.8023 114.247,42.8393 C115.391,43.8763 116.982,44.3948 119.02,44.3948 C121.13,44.3948 122.775,43.8942 123.955,42.893 C125.135,41.8559 125.725,40.5329 125.725,38.9237 C125.725,37.6721 125.35,36.653 124.599,35.8663 C123.884,35.0796 122.972,34.4717 121.863,34.0426 C120.79,33.6135 119.289,33.1486 117.358,32.648 C114.926,32.0043 112.941,31.3607 111.404,30.717 C109.902,30.0376 108.614,29.0006 107.542,27.606 C106.469,26.2114 105.933,24.3519 105.933,22.0275 C105.933,19.882 106.469,18.0047 107.542,16.3955 C108.614,14.7863 110.116,13.5526 112.047,12.6944 C113.978,11.8362 116.213,11.4071 118.752,11.4071 C122.364,11.4071 125.314,12.319 127.603,14.1427 C129.927,15.9306 131.214,18.398 131.465,21.5448 L124.706,21.5448 C124.599,20.186 123.955,19.0238 122.775,18.0583 C121.595,17.0928 120.039,16.61 118.108,16.61 C116.356,16.61 114.926,17.057 113.817,17.951 C112.709,18.845 112.155,20.1323 112.155,21.813 C112.155,22.9573 112.494,23.9049 113.174,24.6558 C113.889,25.371 114.783,25.9432 115.856,26.3723 C116.928,26.8014 118.395,27.2662 120.254,27.7669 C122.721,28.4463 124.724,29.1257 126.262,29.8051 C127.835,30.4846 129.158,31.5395 130.231,32.9698 C131.339,34.3644 131.894,36.2418 131.894,38.6019 C131.894,40.4971 131.375,42.285 130.338,43.9657 C129.337,45.6464 127.853,47.0052 125.886,48.0423 C123.955,49.0435 121.667,49.5441 119.02,49.5441 Z" id="Path" fill="#1D1E1D"></path>
                <path d="M146.243,24.6022 L146.243,40.962 C146.243,42.0705 146.493,42.8751 146.994,43.3757 C147.53,43.8406 148.424,44.073 149.676,44.073 L153.43,44.073 L153.43,49.1687 L148.603,49.1687 C145.849,49.1687 143.74,48.525 142.274,47.2377 C140.807,45.9504 140.074,43.8584 140.074,40.962 L140.074,24.6022 L136.588,24.6022 L136.588,19.6138 L140.074,19.6138 L140.074,12.2653 L146.243,12.2653 L146.243,19.6138 L153.43,19.6138 L153.43,24.6022 L146.243,24.6022 Z" id="Path" fill="#1D1E1D"></path>
                <path d="M157.028,34.2571 C157.028,31.2891 157.636,28.6608 158.852,26.3723 C160.103,24.0837 161.784,22.3136 163.894,21.062 C166.039,19.7747 168.399,19.1311 170.974,19.1311 C173.298,19.1311 175.319,19.5959 177.035,20.5257 C178.787,21.4196 180.182,22.5461 181.219,23.9049 L181.219,19.6138 L187.387,19.6138 L187.387,49.1687 L181.219,49.1687 L181.219,44.7703 C180.182,46.1649 178.769,47.3271 176.981,48.2568 C175.194,49.1865 173.155,49.6514 170.867,49.6514 C168.328,49.6514 166.003,49.0077 163.894,47.7204 C161.784,46.3973 160.103,44.5736 158.852,42.2493 C157.636,39.8892 157.028,37.2251 157.028,34.2571 Z M181.219,34.3644 C181.219,32.3262 180.79,30.5561 179.932,29.0542 C179.109,27.5523 178.018,26.408 176.66,25.6213 C175.301,24.8346 173.835,24.4413 172.261,24.4413 C170.688,24.4413 169.222,24.8346 167.863,25.6213 C166.504,26.3723 165.396,27.4987 164.537,29.0006 C163.715,30.4667 163.304,32.2189 163.304,34.2571 C163.304,36.2954 163.715,38.0834 164.537,39.621 C165.396,41.1586 166.504,42.3387 167.863,43.1611 C169.257,43.9478 170.724,44.3412 172.261,44.3412 C173.835,44.3412 175.301,43.9478 176.66,43.1611 C178.018,42.3744 179.109,41.2302 179.932,39.7283 C180.79,38.1906 181.219,36.4027 181.219,34.3644 Z" id="Shape" fill="#1D1E1D" fillRule="nonzero"></path>
                <path d="M207.327,19.1311 C209.615,19.1311 211.636,19.5959 213.388,20.5257 C215.176,21.4196 216.57,22.5461 217.572,23.9049 L217.572,19.6138 L223.74,19.6138 L223.74,49.6514 C223.74,52.3691 223.168,54.7828 222.024,56.8926 C220.879,59.0382 219.217,60.7188 217.035,61.9346 C214.89,63.1505 212.315,63.7584 209.311,63.7584 C205.306,63.7584 201.981,62.8107 199.334,60.9155 C196.688,59.056 195.186,56.5172 194.829,53.2988 L200.89,53.2988 C201.355,54.8365 202.338,56.0702 203.84,56.9999 C205.378,57.9654 207.201,58.4481 209.311,58.4481 C211.779,58.4481 213.763,57.6972 215.265,56.1953 C216.803,54.6934 217.572,52.5121 217.572,49.6514 L217.572,44.7167 C216.535,46.1113 215.122,47.2913 213.334,48.2568 C211.582,49.1865 209.579,49.6514 207.327,49.6514 C204.752,49.6514 202.392,49.0077 200.246,47.7204 C198.137,46.3973 196.456,44.5736 195.204,42.2493 C193.989,39.8892 193.381,37.2251 193.381,34.2571 C193.381,31.2891 193.989,28.6608 195.204,26.3723 C196.456,24.0837 198.137,22.3136 200.246,21.062 C202.392,19.7747 204.752,19.1311 207.327,19.1311 Z M217.572,34.3644 C217.572,32.3262 217.143,30.5561 216.284,29.0542 C215.462,27.5523 214.371,26.408 213.012,25.6213 C211.653,24.8346 210.187,24.4413 208.614,24.4413 C207.041,24.4413 205.574,24.8346 204.216,25.6213 C202.857,26.3723 201.748,27.4987 200.89,29.0006 C200.068,30.4667 199.656,32.2189 199.656,34.2571 C199.656,36.2954 200.068,38.0834 200.89,39.621 C201.748,41.1586 202.857,42.3387 204.216,43.1611 C205.61,43.9478 207.076,44.3412 208.614,44.3412 C210.187,44.3412 211.653,43.9478 213.012,43.1611 C214.371,42.3744 215.462,41.2302 216.284,39.7283 C217.143,38.1906 217.572,36.4027 217.572,34.3644 Z" id="Shape" fill="#1D1E1D" fillRule="nonzero"></path>
                <path d="M26.5714,48.4635 C27.0353,48.9536 27.404,49.5385 27.6555,50.184 C27.9072,50.8293 28.0368,51.5226 28.0368,52.2227 C28.0368,52.9229 27.9072,53.6161 27.6555,54.2614 C27.404,54.907 27.0353,55.4919 26.5714,55.982 L23.2092,59.5981 L19.7913,55.9067 C17.9152,53.8803 16.8049,51.1745 16.676,48.3153 C16.547,45.4559 17.4087,42.6469 19.0937,40.434 L26.5714,48.4635 Z" id="Path" fill="#17B566"></path>
                <path d="M26.6606,21.6672 C27.5552,22.6328 28.0577,23.9415 28.0577,25.3061 C28.0577,26.6706 27.5552,27.9794 26.6606,28.945 L18.2903,37.967 C17.0952,39.2469 16.148,40.77 15.5038,42.4479 C14.8596,44.1258 14.5311,45.9248 14.5374,47.7407 C14.5395,48.688 14.6306,49.6327 14.8095,50.5602 L11.5101,46.9062 C9.50299,44.7397 8.37567,41.8031 8.37567,38.7414 C8.37567,35.6797 9.50299,32.743 11.5101,30.5764 L23.2427,17.9381 L26.6606,21.6672 Z" id="Path" fill="#17B566"></path>
                <path d="M26.6462,3.70654 C27.5437,4.675 28.0478,5.98785 28.0478,7.35667 C28.0478,8.72551 27.5437,10.0384 26.6462,11.0068 L9.99586,28.9755 C8.48859,30.598 7.38037,32.5998 6.7714,34.7999 C6.16243,36.9999 6.07194,39.3291 6.50814,41.5764 L3.13902,37.9375 C2.14396,36.867 1.3545,35.5954 0.815877,34.1957 C0.277252,32.7958 0,31.2955 0,29.7802 C0,28.2646 0.277252,26.7643 0.815877,25.3644 C1.3545,23.9648 2.14396,22.6932 3.13902,21.6227 L23.2004,0 L26.6462,3.70654 Z" id="Path" fill="#17B566"></path>
            </g>
        </g>
    </svg>
  </span>
);



export const IconLogoLightDesktop = (props: { height?: number, width?: number, color?: string }) => (
  <span className="app-icon app-icon--logo app-icon-logo-light-desktop">
<svg width={props.width ? props.width : '24px'} height={props.height ? props.height : '24px'} viewBox="0 0 224 64" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g>
            <polygon id="Path" fill="#FFFFFF" points="53.3466 16.8246 53.3466 27.7669 66.2199 27.7669 66.2199 32.7553 53.3466 32.7553 53.3466 44.1803 67.829 44.1803 67.829 49.1687 47.2318 49.1687 47.2318 11.8362 67.829 11.8362 67.829 16.8246"></polygon>
            <polygon id="Path" fill="#17B566" points="98.0498 27.1768 98.0498 32.3262 76.0043 32.3262 76.0043 27.1768"></polygon>
            <path d="M119.02,49.5441 C116.517,49.5441 114.264,49.115 112.262,48.2568 C110.259,47.3628 108.686,46.1113 107.542,44.5021 C106.397,42.893 105.825,41.0156 105.825,38.8701 L112.369,38.8701 C112.512,40.4792 113.138,41.8023 114.247,42.8393 C115.391,43.8763 116.982,44.3948 119.02,44.3948 C121.13,44.3948 122.775,43.8942 123.955,42.893 C125.135,41.8559 125.725,40.5329 125.725,38.9237 C125.725,37.6721 125.35,36.653 124.599,35.8663 C123.884,35.0796 122.972,34.4717 121.863,34.0426 C120.79,33.6135 119.289,33.1486 117.358,32.648 C114.926,32.0043 112.941,31.3607 111.404,30.717 C109.902,30.0376 108.614,29.0006 107.542,27.606 C106.469,26.2114 105.933,24.3519 105.933,22.0275 C105.933,19.882 106.469,18.0047 107.542,16.3955 C108.614,14.7863 110.116,13.5526 112.047,12.6944 C113.978,11.8362 116.213,11.4071 118.752,11.4071 C122.364,11.4071 125.314,12.319 127.603,14.1427 C129.927,15.9306 131.214,18.398 131.465,21.5448 L124.706,21.5448 C124.599,20.186 123.955,19.0238 122.775,18.0583 C121.595,17.0928 120.039,16.61 118.108,16.61 C116.356,16.61 114.926,17.057 113.817,17.951 C112.709,18.845 112.155,20.1323 112.155,21.813 C112.155,22.9573 112.494,23.9049 113.174,24.6558 C113.889,25.371 114.783,25.9432 115.856,26.3723 C116.928,26.8014 118.395,27.2662 120.254,27.7669 C122.721,28.4463 124.724,29.1257 126.262,29.8051 C127.835,30.4846 129.158,31.5395 130.231,32.9698 C131.339,34.3644 131.894,36.2418 131.894,38.6019 C131.894,40.4971 131.375,42.285 130.338,43.9657 C129.337,45.6464 127.853,47.0052 125.886,48.0423 C123.955,49.0435 121.667,49.5441 119.02,49.5441 Z" id="Path" fill="#FFFFFF"></path>
            <path d="M146.243,24.6022 L146.243,40.962 C146.243,42.0705 146.493,42.8751 146.994,43.3757 C147.53,43.8406 148.424,44.073 149.676,44.073 L153.43,44.073 L153.43,49.1687 L148.603,49.1687 C145.849,49.1687 143.74,48.525 142.274,47.2377 C140.807,45.9504 140.074,43.8584 140.074,40.962 L140.074,24.6022 L136.588,24.6022 L136.588,19.6138 L140.074,19.6138 L140.074,12.2653 L146.243,12.2653 L146.243,19.6138 L153.43,19.6138 L153.43,24.6022 L146.243,24.6022 Z" id="Path" fill="#FFFFFF"></path>
            <path d="M157.028,34.2571 C157.028,31.2891 157.636,28.6608 158.852,26.3723 C160.103,24.0837 161.784,22.3136 163.894,21.062 C166.039,19.7747 168.399,19.1311 170.974,19.1311 C173.298,19.1311 175.319,19.5959 177.035,20.5257 C178.787,21.4196 180.182,22.5461 181.219,23.9049 L181.219,19.6138 L187.387,19.6138 L187.387,49.1687 L181.219,49.1687 L181.219,44.7703 C180.182,46.1649 178.769,47.3271 176.981,48.2568 C175.194,49.1865 173.155,49.6514 170.867,49.6514 C168.328,49.6514 166.003,49.0077 163.894,47.7204 C161.784,46.3973 160.103,44.5736 158.852,42.2493 C157.636,39.8892 157.028,37.2251 157.028,34.2571 Z M181.219,34.3644 C181.219,32.3262 180.79,30.5561 179.932,29.0542 C179.109,27.5523 178.018,26.408 176.66,25.6213 C175.301,24.8346 173.835,24.4413 172.261,24.4413 C170.688,24.4413 169.222,24.8346 167.863,25.6213 C166.504,26.3723 165.396,27.4987 164.537,29.0006 C163.715,30.4667 163.304,32.2189 163.304,34.2571 C163.304,36.2954 163.715,38.0834 164.537,39.621 C165.396,41.1586 166.504,42.3387 167.863,43.1611 C169.257,43.9478 170.724,44.3412 172.261,44.3412 C173.835,44.3412 175.301,43.9478 176.66,43.1611 C178.018,42.3744 179.109,41.2302 179.932,39.7283 C180.79,38.1906 181.219,36.4027 181.219,34.3644 Z" id="Shape" fill="#FFFFFF" fillRule="nonzero"></path>
            <path d="M207.327,19.1311 C209.615,19.1311 211.636,19.5959 213.388,20.5257 C215.176,21.4196 216.57,22.5461 217.572,23.9049 L217.572,19.6138 L223.74,19.6138 L223.74,49.6514 C223.74,52.3691 223.168,54.7828 222.024,56.8926 C220.879,59.0382 219.217,60.7188 217.035,61.9346 C214.89,63.1505 212.315,63.7584 209.311,63.7584 C205.306,63.7584 201.981,62.8107 199.334,60.9155 C196.688,59.056 195.186,56.5172 194.829,53.2988 L200.89,53.2988 C201.355,54.8365 202.338,56.0702 203.84,56.9999 C205.378,57.9654 207.201,58.4481 209.311,58.4481 C211.779,58.4481 213.763,57.6972 215.265,56.1953 C216.803,54.6934 217.572,52.5121 217.572,49.6514 L217.572,44.7167 C216.535,46.1113 215.122,47.2913 213.334,48.2568 C211.582,49.1865 209.579,49.6514 207.327,49.6514 C204.752,49.6514 202.392,49.0077 200.246,47.7204 C198.137,46.3973 196.456,44.5736 195.204,42.2493 C193.989,39.8892 193.381,37.2251 193.381,34.2571 C193.381,31.2891 193.989,28.6608 195.204,26.3723 C196.456,24.0837 198.137,22.3136 200.246,21.062 C202.392,19.7747 204.752,19.1311 207.327,19.1311 Z M217.572,34.3644 C217.572,32.3262 217.143,30.5561 216.284,29.0542 C215.462,27.5523 214.371,26.408 213.012,25.6213 C211.653,24.8346 210.187,24.4413 208.614,24.4413 C207.041,24.4413 205.574,24.8346 204.216,25.6213 C202.857,26.3723 201.748,27.4987 200.89,29.0006 C200.068,30.4667 199.656,32.2189 199.656,34.2571 C199.656,36.2954 200.068,38.0834 200.89,39.621 C201.748,41.1586 202.857,42.3387 204.216,43.1611 C205.61,43.9478 207.076,44.3412 208.614,44.3412 C210.187,44.3412 211.653,43.9478 213.012,43.1611 C214.371,42.3744 215.462,41.2302 216.284,39.7283 C217.143,38.1906 217.572,36.4027 217.572,34.3644 Z" id="Shape" fill="#FFFFFF" fillRule="nonzero"></path>
            <path d="M26.5714,48.4635 C27.0353,48.9536 27.404,49.5385 27.6555,50.184 C27.9072,50.8293 28.0368,51.5226 28.0368,52.2227 C28.0368,52.9229 27.9072,53.6161 27.6555,54.2614 C27.404,54.907 27.0353,55.4919 26.5714,55.982 L23.2092,59.5981 L19.7913,55.9067 C17.9152,53.8803 16.8049,51.1745 16.676,48.3153 C16.547,45.4559 17.4087,42.6469 19.0937,40.434 L26.5714,48.4635 Z" id="Path" fill="#17B566"></path>
            <path d="M26.6606,21.6672 C27.5552,22.6328 28.0577,23.9415 28.0577,25.3061 C28.0577,26.6706 27.5552,27.9794 26.6606,28.945 L18.2903,37.967 C17.0952,39.2469 16.148,40.77 15.5038,42.4479 C14.8596,44.1258 14.5311,45.9248 14.5374,47.7407 C14.5395,48.688 14.6306,49.6327 14.8095,50.5602 L11.5101,46.9062 C9.50299,44.7397 8.37567,41.8031 8.37567,38.7414 C8.37567,35.6797 9.50299,32.743 11.5101,30.5764 L23.2427,17.9381 L26.6606,21.6672 Z" id="Path" fill="#17B566"></path>
            <path d="M26.6462,3.70654 C27.5437,4.675 28.0478,5.98785 28.0478,7.35667 C28.0478,8.72551 27.5437,10.0384 26.6462,11.0068 L9.99586,28.9755 C8.48859,30.598 7.38037,32.5998 6.7714,34.7999 C6.16243,36.9999 6.07194,39.3291 6.50814,41.5764 L3.13902,37.9375 C2.14396,36.867 1.3545,35.5954 0.815877,34.1957 C0.277252,32.7958 0,31.2955 0,29.7802 C0,28.2646 0.277252,26.7643 0.815877,25.3644 C1.3545,23.9648 2.14396,22.6932 3.13902,21.6227 L23.2004,0 L26.6462,3.70654 Z" id="Path" fill="#17B566"></path>
        </g>
    </g>
</svg>
  </span>
);