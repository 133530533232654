import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import { RootState } from '../../../reducers';
import { connect } from 'react-redux';
import { login } from '../../../actions/auth.action';
import { IconVisibility, IconVisibilityOff, IconWarning } from '../../shared/icons/IconsForm';
import TemplatePageFullwidth from '../../shared/template-page-fullwidth/TemplatePageFullwidth';
import './login.css';
import { IconLogoDarkDesktop } from '../../shared/icons/IconsLogo';
import classnames from 'classnames';
import Language from '../../shared/language/Language';
import { tr, trans } from '../../../translation/translations';
import fetchStates from '../../../types/fetchState.types';
import InterfaceReducerAuth from '../../../intefaces/InterfaceReducerAuth';


interface AuthLoginProps {
  auth: InterfaceReducerAuth,
  login: (options: { login_email: string, login_password: string, keepMeSigned: boolean }) => Promise<any>,
};


interface AuthLoginState {
  formSubmitted: boolean,
  login_password: string,
  login_email: string,
  passwordShow: boolean,
  termsConditionsConsent: boolean,
  termsConditionsError: boolean,
  keepMeSigned: boolean,
  fields: any,
  formError: boolean
};


export class AuthLogin extends React.Component<AuthLoginProps, AuthLoginState> {


  state = {
    formSubmitted: false,
    login_password: '',
    login_email: '',
    passwordShow: false,
    termsConditionsConsent: false,
    termsConditionsError: false,
    keepMeSigned: false,
    fields: {},
    formError: false
  };


  handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState({ formSubmitted: true });
    const { login_email, login_password, keepMeSigned } = this.state;
    await this.props.login({ login_email, login_password, keepMeSigned });
    this.setState({ formSubmitted: false, termsConditionsError: false });
    if(this.props.auth.status === fetchStates.error) {
      this.setState({ formError: true });
      const self = this;
      setTimeout(function() { 
        if(self.state.formError === true) {
          self.setState({ formError: false });
        }
      }, 250);
    }
  };


  handleTermsConditionsContent = () => {
    this.setState({ termsConditionsConsent: !this.state.termsConditionsConsent });
  };


  handleKeepMeSigned = () => {
    this.setState({ keepMeSigned: !this.state.keepMeSigned });
  };


  render() {

    const { authenticated, fields, status } = this.props.auth;
    const { formSubmitted, login_password, login_email, passwordShow, formError } = this.state;

    return authenticated ? <Navigate to="/" /> : (
      <TemplatePageFullwidth>
        <Language />
        <div className="page page--auth page__login">
          <div className={classnames('form-wrapper form-wrapper--auth', { 'form-error': formError })}>
            <div className="logo logo--auth">
              <IconLogoDarkDesktop width={240} height={80} />
            </div>
            <h1 className="title title--main title--auth">{ tr(trans.viewLogin.pageTitle) }</h1>
            <form className="form form--auth" onSubmit={this.handleOnSubmit}>
              <div className="form-group form-group--auth">
                <label 
                  htmlFor="login_email"
                  className={classnames('label label--auth', { 'label--error': fields && fields.includes('login_email') })}
                >
                  { fields && fields.includes('login_email') && <IconWarning size={16} color="#fff" /> }{ tr(trans.viewLogin.form.labelEmail) }
                </label>
                <div className="input-group input-group--auth">
                  <input
                    id="login_email"
                    type="email"
                    autoComplete="login_email"
                    value={login_email}
                    onChange={e => this.setState({ login_email: e.target.value })}
                    className={classnames('input input--auth', { 'input--error': fields && fields.includes('login_email') })}
                  />
                </div>
              </div>
              <div className="form-group">
                <label 
                  htmlFor="login_password"
                  className={classnames('label label--auth', { 'label--error': fields && fields.includes('login_password') })}
                >
                  { fields && fields.includes('login_password') && <IconWarning size={16} color="#fff" /> }{ tr(trans.viewLogin.form.labelPassword) }
                </label>
                <div className="input-group input-group--auth">
                  <span className="password-reveal" onClick={ e => this.setState({ passwordShow: !passwordShow }) }>
                    { passwordShow === false ? (
                      <IconVisibility size={18} color="#000" />
                    ) : (
                      <IconVisibilityOff size={18} color="#000" />
                    )}
                  </span>
                  <input
                    id="login_password"
                    type={ passwordShow === false ? 'password' : 'text' } 
                    autoComplete="login_password"
                    value={ login_password }
                    onChange={e => this.setState({ login_password: e.target.value })}
                    className={classnames('input input--auth input--auth-password', { 'input--error': fields && fields.includes('login_password') })}
                  />
                </div>
              </div>

              <div className="form-group form-group--auth">
                <input 
                  type="submit" 
                  className="button button--auth button--large button-margin--bottom" 
                  value={ tr(trans.viewLogin.form.buttonLogin) } 
                  disabled={formSubmitted && status === 'fetching' ? true : false}
                />
              </div>
            </form>
            <div className="form-footer form-footer--auth">
              <Link className="form-footer__link form-footer__link--auth" to="/register">
                { tr(trans.viewLogin.form.linkRegister) }
              </Link>
            </div>
            <div className="form-footer form-footer--auth">
              <Link className="form-footer__link form-footer__link--auth" to="/password-forgot">
                { tr(trans.viewLogin.form.linkForgotPassword) }
              </Link>
            </div>
          </div>
        </div>
      </TemplatePageFullwidth>
    );
  }
};


export default connect(
  ({ auth }: RootState) => ({ auth }),
  { login }
)(AuthLogin);