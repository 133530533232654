import React from 'react';
import { Link } from 'react-router-dom';
import './tile-navigation-small.css';
import { IconBoxNavigationAboutCaret } from '../icons/IconsBoxNavigation';


interface BoxNavigationSimpleProps {
  icon: JSX.Element,
  to: string,
  title: string
}


const BoxNavigationSimple: React.FC<BoxNavigationSimpleProps> = ({ icon, to, title }) => {
  return (
    <Link 
      to={ to }
      className="box-navigation"
    >
      <div className="box-navigation__icon">
        { icon }
      </div>
      <div className="box-navigation__title">
        { title }
      </div>
      <div className="box-navigation__navigate">
        <IconBoxNavigationAboutCaret />
      </div>
    </Link>
  );
};


export default BoxNavigationSimple;