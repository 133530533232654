import React from 'react';
import { RootState } from '../../../reducers';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';


const WithAuthentication = (Component: any) => {

  interface WithAuthenticationProps {
    auth: { authenticated: boolean },
  }
  

  class WithAuthentication extends React.Component<WithAuthenticationProps> {

    resolveComponent () {
      if(this.props.auth.authenticated === true) {
        return <Component {...this.props} />;
      } else {
        return <Navigate to="/login" />;
      } 
    }

    render() {
      return this.resolveComponent();
    }
  }

  return connect(
    ({ auth }: RootState) => ({ auth }),
    {}
  )(WithAuthentication);

}


export default WithAuthentication;