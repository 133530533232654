import React from 'react';
import './preloader.css';
import { default as ripples } from '../../../assets/ripples.svg';


interface PreloaderProps {
  preloaderVisible: boolean,
  preloaderSmall?: boolean
}


const Preloader: React.FC<PreloaderProps> = ({ preloaderVisible, preloaderSmall }) => {

  if(preloaderVisible === true) {
    return (
      <div className={preloaderSmall ? "preloader-small" : "preloader"}>
        <img src={ ripples } width="200" height="200" alt="preloader" />
      </div>
    );
  }

  return <></>

};


export default Preloader;