import React, { useState } from 'react';
import Navigation from '../navigation/Navigation';
import './template-page-navigation.css';
import Toolbar from '../toolbar/Toolbar';


interface TemplatePageWithNavigationProps {
  children?: JSX.Element | JSX.Element[],
  title?: string,
  buttonBack?: boolean,
}


const TemplatePageWithNavigation: React.FC<TemplatePageWithNavigationProps> = ({ children, title, buttonBack }) => {


  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  }

  return (
    <div className="template template__page template__page--with-navigation">
      <Navigation menuOpened={ isMenuOpen } menuClose={ closeMenu } />
      <div className="main-content">
        <div className="main-content__wrapper">
          <div className="page">
            <Toolbar title={ title || '' } onMenuButtonClick={ toggleMenu } buttonBack={buttonBack} />
            { children }
          </div>
        </div>
      </div>
    </div>
  );
};


export default TemplatePageWithNavigation;