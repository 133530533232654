const languageTranslations = [
  {
    shortcut: 'en',
    title: 'English'
  },
  {
    shortcut: 'pl',
    title: 'Polish'
  },
  {
    shortcut: 'es',
    title: 'Spanish'
  },
  {
    shortcut: 'fr',
    title: 'French'
  }
];

export default languageTranslations;