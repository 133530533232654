import React from 'react';
import { NavLink } from 'react-router-dom';


interface NavigationItemDesktopProps {
  icon: JSX.Element,
  to: string,
  title: string,
  handleClick?: any
}


const NavigationItemDesktop: React.FC<NavigationItemDesktopProps> = ({ title, icon, to, handleClick = () => {}}) => {
  return (
    <div className="navigation__item">
      <NavLink 
        to={to} 
        className="navigation__item-link"
        onClick={handleClick}
      >
        { icon }
        <span className="navigation__item-text">{ title }</span>
      </NavLink>
    </div>
  );
};


export default NavigationItemDesktop;