import React from 'react';


interface AuthorizationProps {
  children?: JSX.Element | JSX.Element[],
  requiredAuthorization: string,
}


const Authorization: React.FC<AuthorizationProps> = ({ children, requiredAuthorization }) => {

  const user_authorized = JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem('user_authorized'))));

  let array_user_authorized: Array<string> = [];

  if(user_authorized) {
    array_user_authorized = Object.entries(user_authorized)
    .filter((entry) => entry[1])
    .map((entry) => entry[0]);
  } else {
    console.error('[ERROR]: user_authorized is missing');
  }

  if(user_authorized && array_user_authorized && array_user_authorized.includes(requiredAuthorization)) {
    return (
      <>
        { children }
      </>
    );
  }

  return null;
};


export default Authorization;