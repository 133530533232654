import React from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { GMAP_API_KEY } from '../../../constants';
import markerCharger from'../../../images/marker_charger.png'
import './map.css';


interface MapProps {
  positionChargers: Array<any>,
  zoom: number
}


const containerStyle = {
  width: '100%',
  height: '400px'
};


const Map: React.FC<MapProps> = ({ positionChargers, zoom }) => {




  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GMAP_API_KEY
  });


  const getMapCenter = () => {
    let lat = 52.23;
    let lng = 23.16;
    positionChargers && positionChargers.length > 0 && positionChargers.map((device: any) => {
      if(device.device_lat && device.device_lng) {
        lat = parseFloat(device.device_lat);
        lng = parseFloat(device.device_lng);
      }
      return null;
    });
    return [lat, lng];
  }


  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={{ lat: getMapCenter()[0], lng: getMapCenter()[1] }}
      zoom={zoom}
      key="1"
    >
      {
        positionChargers && positionChargers.length > 0 && positionChargers.map((device: any) => {
          if(device.device_lat !== null && device.device_lng !== null) {
            return (
              <Marker
                key={device.device_id}
                position={{ lat: parseFloat(device.device_lat), lng: parseFloat(device.device_lng) }}
                icon={
                  // @ts-expect-error
                  new window.google.maps.MarkerImage(
                    markerCharger,
                    null, /* size is determined at runtime */
                    null, /* origin is 0,0 */
                    null, /* anchor is bottom center of the scaled image */
                    new window.google.maps.Size(48, 48)
                  )
                }
              />
            );
          }
          return null;
        })  
      }
    </GoogleMap>
  ) : <></>
}


export default Map;